import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import SimpleModal from 'components/shared/SimpleModal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setError } from 'store/notification.slice';
import { setNewPassword, setShowChangePassword } from 'store/main.slice';
import { validatePassword } from 'utils/validator';

export function ChangePasswordModal(props: any) {
  const dispatch = useDispatch();

  const [ localOldPassword, setLocalOldPassword] = useState('');
  const [ localNewPassword, setLocalNewPassword] = useState('');
  const [ showPassword, setShowPassword] = useState(false);

  const { showChangePassword } = useSelector((state: RootState) => state.main);

  const isInvalid = () => {
    var alert = '';
    if(!localOldPassword || !localNewPassword) {
      alert += 'Invalid passwords.';
    }

    if(!validatePassword(localNewPassword)) {
      alert += 'Invalid new password strength. ';
    }

    return alert ? alert : null;
  };

  const reset = () => {
    setLocalNewPassword('');
    setLocalOldPassword('');
    setShowPassword(false);
  }

  const submitChangePassword = () => {
    var invalid = isInvalid();
    if(!invalid) {
        dispatch(setNewPassword(localOldPassword, localNewPassword));
        reset();
    }
    else {
        dispatch(setError(invalid));
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  const body = (
      <div>
        <h2 id="simple-modal-title">Change Password</h2>
        <FormControl variant="outlined" style={{width:'100%', marginBottom: '20px'}}>
            <InputLabel required htmlFor="outlined-adornment-password">Old Password</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={localOldPassword}
                onChange={(e: any) => {setLocalOldPassword(e.target.value)}}
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {localOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
                }
                label="Password"
            />
        </FormControl>
        <FormControl variant="outlined" style={{width:'100%', marginBottom: '20px'}}>
            <InputLabel required htmlFor="outlined-adornment-password">New Password</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={localNewPassword}
                onChange={(e: any) => {setLocalNewPassword(e.target.value)}}
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {localNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
                }
                label="Password"
            />
        </FormControl>
        <div className="text-right">
            <Button className="mr-2" variant="contained" color="default" onClick={() => {reset();dispatch(setShowChangePassword(false));}}>
                Cancel
            </Button>
            <Button disabled={localNewPassword.length < 6} variant="contained" color="primary" onClick={submitChangePassword}>
                Submit
            </Button>
        </div>
      </div>
  );

	return (
        <SimpleModal open={showChangePassword} body={body} />
      );
}