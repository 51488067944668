import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getAlerts, updateAlert } from 'store/alerts.slice';
import { LoadingModal } from 'components/shared/LoadingModal';
import _ from 'lodash';

export function AnchorAlerts(props: any) {
    const dispatch = useDispatch();
    const [localAlerts, setLocalAlerts] = useState([]);

    const { alerts } = useSelector((state: RootState) => state.alerts);

    const submitAlerts = () => {
        dispatch(updateAlert(localAlerts));
    };

    const setCompareValue = (index: number, value: string) => {
        const tmp = _.cloneDeep(localAlerts);
        tmp[index].compare_value = value;

        setLocalAlerts(tmp);
    }

    const setPercent = (index: number, value: string) => {
        const tmp = _.cloneDeep(localAlerts);
        tmp[index].percent = value;

        setLocalAlerts(tmp); 
    }

    useEffect(() => {
        if(alerts && alerts.length > 0) {
            setLocalAlerts(_.cloneDeep(alerts));
        }
    }, [alerts]);

    useEffect(() => {
        dispatch(getAlerts("anchor"));
    }, [dispatch]);

	return (
        <div className="App text-left">
            {
                <Paper style={{ height: '160px', width: '50%', padding: '1px 16px 16px 16px' }} elevation={3}>
                    {
                        localAlerts.map((alert, index) => (
                            <Grid key={index} container spacing={2} style={{marginTop: '16px'}}>
                                <Grid item xs={4} md={2}>
                                    <Typography><b>{alert.name}</b></Typography>
                                </Grid>
                                <Grid item xs={10} md={5}>
                                    <TextField
                                        style={{width: '100%', marginBottom: '16px'}}
                                        label="Price for Compare"
                                        type="number"
                                        variant="outlined"
                                        value={alert.compare_value}
                                        onChange={(e: any) => {setCompareValue(index, e.target.value)}}
                                    />
                                </Grid>
                                <Grid item xs={10} md={5}>
                                    <TextField
                                        style={{width: '100%', marginBottom: '16px'}}
                                        label="Percent +/-"
                                        type="number"
                                        variant="outlined"
                                        value={alert.percent}
                                        onChange={(e: any) => {setPercent(index, e.target.value)}}
                                    />
                                </Grid>
                            </Grid>
                        ))
                    }
                    <Button style={{float: 'right'}} variant="contained" color="primary" onClick={submitAlerts}>
                        Submit
                    </Button>
                </Paper>
            }
            <LoadingModal />
        </div>
      );
}