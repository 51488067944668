import { GridValueGetterParams } from '@material-ui/data-grid';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL, FUNDS_URL, INVESTORS_URL, WALLETS_URL } from 'utils/constants';
import { getDateTime } from 'utils/formatter';
import { setError, setSuccess } from './notification.slice';
import { AppThunk } from './store';

const fundManagementSlice = createSlice({
  name: 'fundManagement',
  initialState: {
    selectedFund: {id: null, name: ''},
    selectedInvestor: {id: null, name: ''},
    showFundModal: false,
    showManageFundInvestorsModal: false,
    showInvestorModal: false,
    showAddWalletModal: false,
    funds: [],
    investors: [],
    wallets: [],
    fundWallets: [],
    investorWallets: [],
    fundWalletColumns: [
    {
        field: 'investor', 
        headerName: 'Investor',
        sortable: true,
        width: 200,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.investor.name;
        }
    },
    {
        field: 'public_address_display', 
        headerName: 'Wallet',
        sortable: true,
        width: 130,
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        width: 180,
        valueGetter: (params: GridValueGetterParams) => 
        `${getDateTime(params.value)}`,
    }
  ],
  },
  reducers: {
    _setSelectedFund(state, action) {
        state.selectedFund = action.payload;
    },
    _setSelectedInvestor(state, action) {
      state.selectedInvestor = action.payload;
  },
    _setShowFundModal(state, action) {
        state.showFundModal = action.payload;
    },
    _setShowManageFundInvestorsModal(state, action) {
      state.showManageFundInvestorsModal = action.payload;
    },
    _setShowInvestorModal(state, action) {
        state.showInvestorModal = action.payload;
    },
    _setShowAddWalletModal(state, action) {
        state.showAddWalletModal = action.payload;
    },
    _setFunds(state, action) {
        state.funds = action.payload;
    },
    _setInvestors(state, action) {
        state.investors = action.payload;
    },
    _setWallets(state, action) {
      state.wallets = action.payload;
    },
    _setFundWallets(state, action) {
      state.fundWallets = action.payload;
    },
    _setInvestorWallets(state, action) {
      state.investorWallets = action.payload;
    }
  }
});

const {
    _setSelectedFund,
    _setSelectedInvestor,
    _setShowFundModal,
    _setShowManageFundInvestorsModal,
    _setShowInvestorModal,
    _setShowAddWalletModal,
    _setFunds,
    _setInvestors,
    _setWallets,
    _setFundWallets,
    _setInvestorWallets
} = fundManagementSlice.actions;


export const addWallet = (investorId: number, password: string, privateKey: string, decryptionKey: string): AppThunk => async (dispatch) => {
    var response = await axios.post(`${API_URL}${WALLETS_URL}add`, {investorId: investorId, password: password, privateKey: privateKey, decryptionKey: decryptionKey});
    console.log("response", response);
    dispatch(setShowAddWalletModal(false));
    if(response.data.error) {
      dispatch(setError(response.data.error));
    }
    else {
      dispatch(getWalletsByInvestor(investorId));
    }
};

export const deleteFund = (): AppThunk => async (dispatch, getState) => {
  await axios.post(`${API_URL}${FUNDS_URL}deleteFund`, {id: getState().fundManagement.selectedFund.id});
  dispatch(_setSelectedFund({}));
  dispatch(_setFundWallets([]));
  dispatch(setSuccess("Success!"));
  dispatch(getFunds());
};

export const deleteInvestor = (): AppThunk => async (dispatch, getState) => {
  await axios.post(`${API_URL}${INVESTORS_URL}deleteInvestor`, {id: getState().fundManagement.selectedInvestor.id});
  dispatch(_setSelectedInvestor({}));
  dispatch(_setInvestorWallets([]));
  dispatch(setSuccess("Success!"));
  dispatch(getInvestors());
};
  
export const deleteWallets = (ids: number[]): AppThunk => async (dispatch, getState) => {
    await axios.post(`${API_URL}${WALLETS_URL}delete`, {ids: ids});
    dispatch(getWalletsByInvestor(getState().fundManagement.selectedInvestor.id));
};
  
export const setSelectedFund = (fund: any): AppThunk => async (dispatch) => {
  dispatch(_setSelectedFund(fund));
};

export const setSelectedInvestor = (investor: any): AppThunk => async (dispatch) => {
  dispatch(_setSelectedInvestor(investor));
};

export const setShowFundModal = (value: boolean): AppThunk => async (dispatch) => {
    dispatch(_setShowFundModal(value));
};

export const setShowManageFundInvestorsModal = (value: boolean): AppThunk => async (dispatch) => {
  dispatch(_setShowManageFundInvestorsModal(value));
};

export const setShowInvestorModal = (value: boolean): AppThunk => async (dispatch) => {
    dispatch(_setShowInvestorModal(value));
};

export const setShowAddWalletModal = (value: boolean): AppThunk => async (dispatch) => {
    dispatch(_setShowAddWalletModal(value));
};
  
export const getFunds = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}${FUNDS_URL}allFunds`);
    dispatch(_setFunds(response.data));

    if(getState().fundManagement.selectedFund) {
      const updatedFund = getState().fundManagement.funds.find((e) => e.id === getState().fundManagement.selectedFund.id);
      if(updatedFund) {
        dispatch(_setSelectedFund(updatedFund));
      }
    }
};

export const getInvestors = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}${INVESTORS_URL}allInvestors`);
    dispatch(_setInvestors(response.data));

    if(getState().fundManagement.selectedFund) {
      const updatedInvestor = getState().fundManagement.investors.find((e) => e.id === getState().fundManagement.selectedInvestor.id);
      if(updatedInvestor) {
        dispatch(_setSelectedInvestor(updatedInvestor));
      }
    }
};

export const walletSort = (a: any, b: any) => {
  if ( a.investor.name.toLowerCase() < b.investor.name.toLowerCase()){
    return -1;
  }
  if ( a.investor.name.toLowerCase() > b.investor.name.toLowerCase()){
    return 1;
  }
  return 0;
};

export const getWallets = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}${WALLETS_URL}all`);  
    response.data.wallets.sort(walletSort);
    dispatch(_setWallets(response.data.wallets));
};

export const getWalletsByInvestor = (investorId: number): AppThunk => async (dispatch) => {
  const response = await axios.get(`${API_URL}${WALLETS_URL}investor/${investorId}`);  
  response.data.wallets.sort(walletSort);
  dispatch(_setInvestorWallets(response.data.wallets));
};

export const getWalletsByFund = (fundId: number): AppThunk => async (dispatch) => {
  const response = await axios.get(`${API_URL}${WALLETS_URL}fund/${fundId}`);  
  response.data.sort(walletSort);
  dispatch(_setFundWallets(response.data));
};

export const saveFundWallets = (id: number, walletIds: number[]): AppThunk => async (dispatch) => {
  await axios.post(`${API_URL}${FUNDS_URL}addWallets`, {id: id, walletIds: walletIds});
  //dispatch(_setSelectedFund(response.data));
  dispatch(setSuccess("Success!"));
  dispatch(getWalletsByFund(id));
}

export const saveFund = (fund: any): AppThunk => async (dispatch) => {
    const url = fund.id ? 'updateFund' : 'createFund';

    const response = await axios.post(`${API_URL}${FUNDS_URL}${url}`, fund);
    if(response.data) {
        dispatch(_setSelectedFund(response.data));
        dispatch(setSuccess("Success!"));
        dispatch(getFunds());
        dispatch(getWalletsByFund(response.data.id));
    }
    else {
        dispatch(setError("Error!"));
    }
};

export const saveInvestor = (investor: any): AppThunk => async (dispatch) => {
    const url = investor.id ? 'updateInvestor' : 'createInvestor';

  const response = await axios.post(`${API_URL}${INVESTORS_URL}${url}`, investor);
  if(response.data) {
    dispatch(_setSelectedInvestor(response.data));
    dispatch(setSuccess("Success!"));
    dispatch(getInvestors());
  }
  else {
    dispatch(setError("Error!"));
  }
};

export default fundManagementSlice.reducer;