import { Box, Button, Divider, Grid, IconButton, Paper, Switch, Tab, Tabs, TextField, Tooltip, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { LoadingModal } from 'components/shared/LoadingModal';
import _ from 'lodash';
import { getStrategySetting, getAllStrategySettings, setWalletSettings, updateSetting, updateStrategySetting } from 'store/strategies.slice';
import { InfoOutlined } from '@material-ui/icons';
import { SolanaAlerts } from 'components/alerts/SolanaAlerts';
import { STRATEGY_SOLANA } from 'utils/constants';

export function Solana(props: any) {
    const dispatch = useDispatch();
    const [tab, setTab] = useState(0);

    const { strategySetting, walletSettings } = useSelector((state: RootState) => state.strategies);

    const changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    const saveSettings = () => {
        dispatch(updateSetting(walletSettings, getAllStrategySettings));
    }

    const addWallet = () => {
        const tmp = _.cloneDeep(walletSettings);
        const newWalletSetting:any = {};
        newWalletSetting.key_type = STRATEGY_SOLANA;
        newWalletSetting.key_type_value = "New Wallet";
        newWalletSetting.origin_price = 0;
        newWalletSetting.key = "Toggle";
        newWalletSetting.value = "On";
        newWalletSetting.threshold = 10;
        newWalletSetting.minimum_diff = 2;
        tmp.unshift(newWalletSetting);
        dispatch(setWalletSettings(tmp));
    };


    const changeSetting = (index: number, property: string, eventValue: any) => {
        const tmp = _.cloneDeep(walletSettings);
        let value = eventValue;
        if(property === "value") {
            value = eventValue ? "On" : "Off";
        }
        tmp[index][property] = value;
        dispatch(setWalletSettings(tmp));
    };

    useEffect(() => {
        dispatch(getStrategySetting());
        dispatch(getAllStrategySettings());
    }, [dispatch]);

	return (
        <div className="App text-left">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Tooltip title="Turns the entire systems Solana strategy On or Off. ">
                        <IconButton style={{marginRight: '8px', marginTop: '-10px'}} >
                            <InfoOutlined />
                        </IconButton>
                    </Tooltip>
                    <Grid item style={{display:'inline-block'}}><h2 className="header-text">Solana</h2></Grid>
                    <Grid item style={{display:'inline-block', marginLeft: '32px'}}>
                        <Typography component="div">
                            <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item className="bold">Off</Grid>
                            <Grid item>
                                <Switch checked={strategySetting.value === 'On'} onChange={(e) => {dispatch(updateStrategySetting(e.target.checked))}} color="primary" name="checkedC" />
                            </Grid>
                            <Grid item className="bold">On</Grid>
                            </Grid>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Box mb={2}>
                <Divider  />
            </Box>
            <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={changeTab}
            >
                <Tab key={0} label="Wallets Config" />
                <Tab key={1} label="Alerts" />
            </Tabs>
            {
                tab === 0 ? <Paper style={{ width: '50%', marginTop: '16px', padding: '16px 16px 16px 16px' }} elevation={3}>
                    <div style={{marginBottom: '24px', display: 'inline-block'}}>
                        <Button variant="contained" color="primary" onClick={addWallet}>
                            Add Wallet
                        </Button>
                    </div>
                    <div style={{marginBottom: '24px', display: 'inline-block', float: 'right'}}>
                        <Button variant="contained" color="primary" onClick={saveSettings}>
                            Save
                        </Button>
                    </div>
                    {
                        walletSettings.map((walletSetting, index) => (
                            <Grid item  key={index} style={{marginTop: '16px'}}>
                                <Grid item >
                                    <Typography className='inline-block' component="div">
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item className="bold">Off</Grid>
                                        <Grid item>
                                            <Switch checked={walletSetting.value === 'On'} onChange={(event) => {changeSetting(index, "value", event.target.checked)}} color="primary" name="checkedC" />
                                        </Grid>
                                        <Grid item className="bold">On</Grid>
                                        </Grid>
                                    </Typography>
                                    <Grid key={index} container spacing={2}>
                                        <Grid item xs={6} md={3}>
                                            <TextField
                                                disabled={walletSetting.value === "Off"}
                                                style={{width: '100%', marginBottom: '16px'}}
                                                label="Wallet Last 3"
                                                type="text"
                                                variant="outlined"
                                                value={walletSetting.key_type_value}
                                                onChange={(event: any) => {changeSetting(index, "key_type_value", event.target.value)}}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <TextField
                                                disabled={walletSetting.value === "Off"}
                                                style={{width: '100%', marginBottom: '16px'}}
                                                label="Origin Price"
                                                type="number"
                                                variant="outlined"
                                                value={walletSetting.origin_price}
                                                onChange={(event: any) => {changeSetting(index, "origin_price", event.target.value)}}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <TextField
                                                disabled={walletSetting.value === "Off"}
                                                style={{width: '100%', marginBottom: '16px'}}
                                                label="Threshold %"
                                                type="number"
                                                variant="outlined"
                                                value={walletSetting.threshold}
                                                onChange={(event: any) => {changeSetting(index, "threshold", event.target.value)}}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <TextField
                                                disabled={walletSetting.value === "Off"}
                                                style={{width: '100%', marginBottom: '16px'}}
                                                label="Amount Invested"
                                                type="number"
                                                variant="outlined"
                                                value={walletSetting.amount_invested}
                                                onChange={(event: any) => {changeSetting(index, "amount_invested", event.target.value)}}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Paper>
                : 
                null
            }
             {
                tab === 1 ? 
                    <SolanaAlerts />
                    :
                    null 
            }
            <LoadingModal message={'Saving Solana Configs...'} />
        </div>
      );
}