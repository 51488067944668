import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL, PROTOCOLS_URL, SETTINGS_URL, STRATEGY_SOLANA } from 'utils/constants';
import { setSuccess } from './notification.slice';
import { AppThunk } from './store';
import { setShowLoading } from './main.slice';
import _ from 'lodash';


const strategiesSlice = createSlice({
  name: 'strategies',
  initialState: {
    lpLongLookups: [],
    selectedStrategy: STRATEGY_SOLANA,
    strategySetting: {value: 'Off'},
    walletSettings: []
  },
  reducers: {
    _setLpLongLookups(state, action) {
      state.lpLongLookups = action.payload;
    },
    _setSelectedStrategy(state, action) {
      state.selectedStrategy = action.payload;
    },
    _setStrategySetting(state, action) {
      state.strategySetting = action.payload;
    },
    _setWalletSettings(state, action) {
      state.walletSettings = action.payload;
    }
  }
});

const {
  _setLpLongLookups,
  _setSelectedStrategy,
  _setStrategySetting,
  _setWalletSettings
} = strategiesSlice.actions;

export const executeAnchorBuySell = (): AppThunk => async (dispatch) => {
  dispatch(setShowLoading(true));
  const response = await axios.post(`${API_URL}${PROTOCOLS_URL}anchorCheckBuySell`);
  console.log("buySell", response.data.setting);
  dispatch(setShowLoading(false));
  dispatch(setSuccess("Anchor Buy/Sell executed!"));
};

export const getStrategySetting = (): AppThunk => async (dispatch, getState) => {
  const strat = getState().strategies.selectedStrategy.toLowerCase().replaceAll("/", "");
  const response = await axios.get(`${API_URL}${SETTINGS_URL}${strat}`);
  console.log("strategy setting", response.data.setting);
  dispatch(_setStrategySetting(response.data.setting));
};

export const getLpLongLookups = (): AppThunk => async (dispatch) => {
  const response = await axios.get(`${API_URL}${SETTINGS_URL}allLPLong`);
  console.log("settings", response.data.settings);
  dispatch(_setLpLongLookups(response.data.settings));
};

export const getAllStrategySettings = (): AppThunk => async (dispatch, getState) => {
  const strat = getState().strategies.selectedStrategy.toLowerCase().replaceAll("/", "");
  const response = await axios.get(`${API_URL}${SETTINGS_URL}all-${strat}`);
  console.log("settings", response.data.settings);
  dispatch(_setWalletSettings(response.data.settings));
}; 

export const setSelectedStrategy = (strategy: string): AppThunk => async (dispatch) => {
  dispatch(_setSelectedStrategy(strategy));
}; 

export const setWalletSettings = (walletSettings: any[]): AppThunk => async (dispatch) => {
  dispatch(_setWalletSettings(walletSettings));
}; 

export const updateSetting = (settings: any[], functionName: Function): AppThunk => async (dispatch) => {
  await axios.post(`${API_URL}${SETTINGS_URL}update`, {settings: settings});
  dispatch(setSuccess("Success!"));
  dispatch(functionName());
};

export const updateStrategySetting = (checked: boolean): AppThunk => async (dispatch, getState) => {
  const tmp = _.cloneDeep(getState().strategies.strategySetting);
  tmp.value = checked ? "On" : "Off";
  dispatch(updateSetting([tmp], getStrategySetting));
};


export default strategiesSlice.reducer;