import { Button, Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import SimpleModal from 'components/shared/SimpleModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setShowManageFundInvestorsModal, saveFundWallets } from 'store/fundManagement.slice';

export function FundManageInvestors(props: any) {
  const dispatch = useDispatch();
  const paginationSize = 100;
  const [selectedWalletIds, setSelectedWalletIds] = useState([]);
  const { selectedFund, showManageFundInvestorsModal, wallets, fundWalletColumns, fundWallets } = useSelector((state: RootState) => state.fundManagement);

  const fundSelectionModelChange = (e: any) => {
    setSelectedWalletIds(e);
    console.log(e);
  };

  const save = () => {
    dispatch(saveFundWallets(selectedFund.id, selectedWalletIds));
    dispatch(setShowManageFundInvestorsModal(false));
  };


  useEffect(() => {
    setSelectedWalletIds(fundWallets.map(x => x.id));
}, [fundWallets]);

  const body = (
      <div>
        <h2 id="simple-modal-title">{selectedFund.name + " - Manage Investors"}</h2>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <DataGrid
                    style={{ height: 'calc(100vh - 250px)' }}
                    rows={wallets}
                    columns={fundWalletColumns}
                    pageSize={paginationSize}
                    disableSelectionOnClick
                    checkboxSelection={true}
                    onSelectionModelChange={fundSelectionModelChange}
                    selectionModel={selectedWalletIds}
                />
            </Grid>
        </Grid>
        <div className="text-right" style={{marginTop: '16px'}}>
            <Button className="mr-2" variant="contained" color="default" onClick={() => {dispatch(setShowManageFundInvestorsModal(false))}}>
                Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={save}>
                Save
            </Button>
        </div>
      </div>
  );

	return (
        <SimpleModal open={showManageFundInvestorsModal} body={body} width={'calc(100vw - 200px)'} />
      );
}