import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL, AUTH_URL } from 'utils/constants';
import { setError } from './notification.slice';
import { AppThunk } from './store';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    defaultPath: '/dashboard',
    token: null,
    decryptionKey: null,
    showDecryptionKey: false
  },
  reducers: {
    _setDecriptionKey(state, action) {
      state.decryptionKey = action.payload;
      sessionStorage.setItem('decryptionKey', state.decryptionKey);
    },
    _setShowDecryptionKey(state, action) {
      state.showDecryptionKey = action.payload;
    },
    _setUser(state, action) {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    _setToken(state, action) {
        state.token = action.payload;
        localStorage.setItem('token', state.token);
        axios.defaults.headers.common['Authorization'] ='Bearer ' + state.token;
    },
  }
});

const {
  _setDecriptionKey,
  _setShowDecryptionKey,
  _setToken,
  _setUser } = authSlice.actions;

export const attemptLogin = (login: any, navigate: any): AppThunk => async (dispatch, getState) => {
    const response = await axios.post(`${API_URL}${AUTH_URL}login`, login);
    console.log('result', response.data);
    if(response.data.result) {
      dispatch(setToken(response.data.token.token));
      dispatch(setUser(response.data.user));
      navigate(getState().auth.defaultPath);
    }
    else {
      dispatch(setError("Invalid username or password."));
    }
};
  
export const register = (login: any, navigate: any): AppThunk => async (dispatch, getState) => {
    const response = await axios.post(`${API_URL}${AUTH_URL}register`, login);
    if(response.data.result) {
      dispatch(setToken(response.data.token.token));
      dispatch(setUser(response.data.user));
      navigate(getState().auth.defaultPath);
    }
    else {
      dispatch(setError("Registration Error"));
    }
};

export const setDecryptionKey = (decryptionKey: string): AppThunk => async (dispatch) => {
  dispatch(_setDecriptionKey(decryptionKey));
  dispatch(setShowDecryptionKey(false));
};

export const setLogout = (navigate: any, callLogout: boolean = true): AppThunk => async (dispatch) => {
    dispatch(setUser(null));
    dispatch(setToken(null));
    navigate('/');

    if(callLogout) {
      await axios.post(`${API_URL}${AUTH_URL}logout`);
    }
};

export const setShowDecryptionKey = (value: boolean): AppThunk => async (dispatch) => {
  dispatch(_setShowDecryptionKey(value));
};


export const setToken = (value: string): AppThunk => async (dispatch) => {
  dispatch(_setToken(value));
};

export const setUser = (value: any): AppThunk => async (dispatch) => {
  dispatch(_setUser(value));
};

export const setAxios = (navigate: any): AppThunk => async (dispatch) => {
    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
     }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if(!error.response) {
          dispatch(setError('Server unavailable.'));
          dispatch(setLogout(navigate, false));
        }
        else if (error.response.status === 401) {
            dispatch(setError('Authentication Expired.'));
            dispatch(setLogout(navigate));
        }
        else {
          dispatch(setError('Error ' + error.response.status + '.'));
        }
        return Promise.reject(error);
     });
}

export default authSlice.reducer;