import { Button, TextField } from '@material-ui/core';
import SimpleModal from 'components/shared/SimpleModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { saveInvestor, setShowInvestorModal } from 'store/fundManagement.slice';

export function InvestorModal(props: any) {
  const dispatch = useDispatch();

  const { selectedInvestor, showInvestorModal } = useSelector((state: RootState) => state.fundManagement);
  const [ investorName, setInvestorName] = useState(selectedInvestor.name);

  const save = () => {
    dispatch(saveInvestor({id: selectedInvestor.id, name: investorName}));
    dispatch(setShowInvestorModal(false));
  };

  useEffect(() => {
    setInvestorName(selectedInvestor.name);
  }, [selectedInvestor]);

  const body = (
      <div>
        <h2 id="simple-modal-title">Save Investor</h2>
        <TextField
          style={{width: '100%', marginBottom: '16px'}}
          label="Investor Name"
          variant="outlined"
          value={investorName}
          onChange={(e: any) => {setInvestorName(e.target.value)}}
        />
        <div className="text-right">
            <Button className="mr-2" variant="contained" color="default" onClick={() => {dispatch(setShowInvestorModal(false))}}>
                Cancel
            </Button>
            <Button disabled={!investorName} variant="contained" color="primary" onClick={save}>
                Save
            </Button>
        </div>
      </div>
  );

	return (
        <SimpleModal open={showInvestorModal} body={body} />
      );
}