import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL, SNAPSHOTS_URL } from 'utils/constants';
import { setShowLoading } from './main.slice';
import { AppThunk } from './store';


const snapshotSlice = createSlice({
  name: 'snapshot',
  initialState: {
    snapshots: []
  },
  reducers: {
    _setSnapshots(state, action) {
      state.snapshots = action.payload;
    }
  }
});

const {
    _setSnapshots
} = snapshotSlice.actions;


export const getSnapshots = (): AppThunk => async (dispatch) => {
  const response = await axios.post(`${API_URL}${SNAPSHOTS_URL}all`);
  console.log("snapshots", response.data.snapshots);
  dispatch(_setSnapshots(response.data.snapshots));
};

export const createSnapshot = (): AppThunk => async (dispatch) => {
  dispatch(setShowLoading(true));
  const response = await axios.post(`${API_URL}${SNAPSHOTS_URL}create`);
  dispatch(_setSnapshots(response.data.snapshots));
  dispatch(setShowLoading(false));
};

export default snapshotSlice.reducer;