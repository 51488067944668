import { Box, Button, Divider, Grid, IconButton, Paper, Switch, Tab, Tabs, TextField, Tooltip, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { LoadingModal } from 'components/shared/LoadingModal';
import _ from 'lodash';
import { executeAnchorBuySell, getStrategySetting, getLpLongLookups, getAllStrategySettings, setWalletSettings, updateSetting, updateStrategySetting } from 'store/strategies.slice';
import { InfoOutlined } from '@material-ui/icons';
import { AnchorAlerts } from 'components/alerts/AnchorAlerts';

export function Anchor(props: any) {
    const dispatch = useDispatch();
    const [tab, setTab] = useState(0);
    const [localLpLongs, setLocalLpLongs] = useState([]);

    const { lpLongLookups, strategySetting, walletSettings } = useSelector((state: RootState) => state.strategies);

    const changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    const setKey = (index: number, value: string) => {
        const tmp = _.cloneDeep(localLpLongs);
        tmp[index].key = value;

        setLocalLpLongs(tmp);
    }

    const setValue = (index: number, value: string) => {
        const tmp = _.cloneDeep(localLpLongs);
        tmp[index].value = value;

        setLocalLpLongs(tmp);
    }

    const submitLPLongs = () => {
        dispatch(updateSetting(localLpLongs, getLpLongLookups));
    };

    const saveWalletBuySell = () => {
        dispatch(updateSetting(walletSettings, getAllStrategySettings));
    }

    const executeWalletBuySell = () => {
        dispatch(executeAnchorBuySell());
    }

    const addLPLong = () => {
        const tmp = _.cloneDeep(localLpLongs);
        const newLPLong:any = {};
        newLPLong.key_type = "LPLongLookup";
        newLPLong.key = "0.00";
        newLPLong.value = "0.00";
        tmp.unshift(newLPLong);
        setLocalLpLongs(tmp);
    };

    const changeWalletBuySell = (index: number, property: string, eventValue: any) => {
        const tmp = _.cloneDeep(walletSettings);
        let value = eventValue;
        if(property === "value") {
            value = eventValue ? "On" : "Off";
        }
        tmp[index][property] = value;
        dispatch(setWalletSettings(tmp));
    };

    useEffect(() => {
        if(lpLongLookups && lpLongLookups.length > 0) {
            setLocalLpLongs(_.cloneDeep(lpLongLookups));
        }
    }, [lpLongLookups]);

    useEffect(() => {
        dispatch(getLpLongLookups());
        dispatch(getStrategySetting());
        dispatch(getAllStrategySettings());
    }, [dispatch]);

	return (
        <div className="App text-left">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Tooltip title="Turns the entire systems Anchor Buy/Sell strategy On or Off.  When turned Off no wallets will run regardless of their individual settings.">
                        <IconButton style={{marginRight: '8px', marginTop: '-10px'}} >
                            <InfoOutlined />
                        </IconButton>
                    </Tooltip>
                    <Grid item style={{display:'inline-block'}}><h2 className="header-text">Anchor Buy/Sell</h2></Grid>
                    <Grid item style={{display:'inline-block', marginLeft: '32px'}}>
                        <Typography component="div">
                            <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item className="bold">Off</Grid>
                            <Grid item>
                                <Switch checked={strategySetting.value === 'On'} onChange={(e) => {dispatch(updateStrategySetting(e.target.checked))}} color="primary" name="checkedC" />
                            </Grid>
                            <Grid item className="bold">On</Grid>
                            </Grid>
                        </Typography>
                    </Grid>
                </Grid>
                {/* <Grid item xs={6} style={{textAlign:'right'}}>
                    
                </Grid> */}
            </Grid>
            <Box mb={2}>
                <Divider  />
            </Box>
            <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={changeTab}
            >
                <Tab key={0} label="Wallets Config" />
                <Tab key={1} label="LP Long Lookups" />
                <Tab key={2} label="Alerts" />
            </Tabs>
            {
                tab === 0 ? 
                        <>
                        {
                            <Paper style={{ width: '50%', marginTop: '16px', padding: '16px 16px 16px 16px' }} elevation={3}>
                                 <div style={{marginBottom: '24px', display: 'inline-block'}}>
                                    <Button variant="contained" color="primary" onClick={executeWalletBuySell}>
                                        Execute Now
                                    </Button>
                                </div>
                                 <div style={{marginBottom: '24px', display: 'inline-block', float: 'right'}}>
                                    <Button variant="contained" color="primary" onClick={saveWalletBuySell}>
                                        Save
                                    </Button>
                                </div>
                                {
                                    walletSettings.map((walletSetting, index) => (
                                        <Grid item  key={index} style={{marginTop: '16px'}}>
                                            <Grid item >
                                                <Typography className='inline-block buy-sell-wallet'> {walletSetting.key_type_value.slice(walletSetting.key_type_value.length - 3)}</Typography>
                                                <Typography className='inline-block-lm' component="div">
                                                    <Grid component="label" container alignItems="center" spacing={1}>
                                                    <Grid item className="bold">Off</Grid>
                                                    <Grid item>
                                                        <Switch checked={walletSetting.value === 'On'} onChange={(event) => {changeWalletBuySell(index, "value", event.target.checked)}} color="primary" name="checkedC" />
                                                    </Grid>
                                                    <Grid item className="bold">On</Grid>
                                                    </Grid>
                                                </Typography>
                                                <Grid key={index} container spacing={2}>
                                                    <Grid item xs={8} md={4}>
                                                        <TextField
                                                            disabled={walletSetting.value === "Off"}
                                                            style={{width: '100%', marginBottom: '16px'}}
                                                            label="Current Longs"
                                                            type="number"
                                                            variant="outlined"
                                                            value={walletSetting.longs}
                                                            onChange={(event: any) => {changeWalletBuySell(index, "longs", event.target.value)}}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8} md={4}>
                                                        <TextField
                                                            disabled={walletSetting.value === "Off"}
                                                            style={{width: '100%', marginBottom: '16px'}}
                                                            label="Current Shorts"
                                                            type="number"
                                                            variant="outlined"
                                                            value={walletSetting.shorts}
                                                            onChange={(event: any) => {changeWalletBuySell(index, "shorts", event.target.value)}}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8} md={4}>
                                                        <TextField
                                                            disabled={walletSetting.value === "Off"}
                                                            style={{width: '100%', marginBottom: '16px'}}
                                                            label="Threshold %"
                                                            type="number"
                                                            variant="outlined"
                                                            value={walletSetting.threshold}
                                                            onChange={(event: any) => {changeWalletBuySell(index, "threshold", event.target.value)}}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Paper>
                        }
                    </>
                    :
                    null 
            }
            {
                tab === 1 ? 
                        <>
                        {
                            <Paper style={{ width: '50%', marginTop: '16px', padding: '16px 16px 16px 16px' }} elevation={3}>
                                 <div style={{textAlign: 'right', marginBottom: '24px'}}>
                                    <Button style={{float:'left'}} variant="contained" color="primary" onClick={addLPLong}>
                                        Add Item
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={submitLPLongs}>
                                        Submit
                                    </Button>
                                </div>
                                {
                                    localLpLongs.map((setting, index) => (
                                        <Grid key={index} container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    style={{width: '100%', marginBottom: '16px'}}
                                                    label="Lookup Value $"
                                                    type="number"
                                                    variant="outlined"
                                                    value={setting.key}
                                                    onChange={(e: any) => {setKey(index, e.target.value)}}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    style={{width: '100%', marginBottom: '16px'}}
                                                    label="Longs Returned"
                                                    type="number"
                                                    variant="outlined"
                                                    value={setting.value}
                                                    onChange={(e: any) => {setValue(index, e.target.value)}}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Paper>
                        }
                    </>
                    :
                    null 
            }
            {
                tab === 2 ? 
                    <AnchorAlerts />
                    :
                    null 
            }
            <LoadingModal message={'Executing Anchor Buy/Sell...'} />
        </div>
      );
}