import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import rootReducer from './reducers';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

const store = configureStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
      <Router>
        <ToastProvider>
          <App />
        </ToastProvider>
      </Router>
  </Provider>,
  document.getElementById('root')
);