import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL, FILTER_ALERT, FILTER_HIDE_NOACTION, LOGS_URL, MANGO_TRANSACTIONS_URL } from 'utils/constants';
import { AppThunk } from './store';


const logsSlice = createSlice({
  name: 'logs',
  initialState: {
    logs: [],
    mangoTransactions: [],
    selectedFilterLog: 'default'
  },
  reducers: {
    _setLogs(state, action) {
      state.logs = action.payload;
    },
    _setMangoTransactions(state, action) {
      state.mangoTransactions = action.payload;
    },
    _setSelectedFilterLog(state, action) {
      state.selectedFilterLog = action.payload;
    }
  }
});

const {
    _setLogs,
    _setMangoTransactions,
    _setSelectedFilterLog
} = logsSlice.actions;

export const getLogs = (startDate: Date, endDate: Date): AppThunk => async (dispatch) => {
  const response = await axios.post(`${API_URL}${LOGS_URL}username`, {username: 'system', startDate: startDate.toLocaleDateString(), endDate: endDate.toLocaleDateString()});
  console.log("logs", response.data.logs);
  dispatch(_setLogs(response.data.logs));
};

export const getMangoTransactions = (startDate: Date, endDate: Date): AppThunk => async (dispatch, getState) => {
  const response = await axios.post(`${API_URL}${MANGO_TRANSACTIONS_URL}transactions`, {startDate: startDate.toLocaleDateString(), endDate: endDate.toLocaleDateString()});
  console.log("mango transactions", response.data.transactions);

  let filtered = response.data.transactions;
  if(getState().logs.selectedFilterLog === FILTER_ALERT) {
    filtered = filtered.filter((x: any) => x.transaction_message.toLowerCase().indexOf("alert") > -1);
  }
  else if(getState().logs.selectedFilterLog === FILTER_HIDE_NOACTION) {
    filtered = filtered.filter((x: any) => x.transaction_message.toLowerCase().indexOf("no action") === -1);
  }

  dispatch(_setMangoTransactions(filtered));
};

export const setSelectedFilterLog = (selectedFilter: string): AppThunk => async (dispatch) => {
  dispatch(_setSelectedFilterLog(selectedFilter));
};

export default logsSlice.reducer;