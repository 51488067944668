import { Box, Button, Divider } from '@material-ui/core';
import { ChangePasswordModal } from 'components/shared/ChangePasswordModal';
import { useDispatch } from 'react-redux';
import { setShowChangePassword } from 'store/main.slice';

export function Profile(props: any) {
    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(getWallets());
    // }, [dispatch]);

	return (
        <div className="App text-left">
            <h1 className="header-text">Profile</h1>
            <Box mb={2}>
                <Divider  />
            </Box>
            <Box mb={2}>
                <Button style={{float: 'right'}} variant="contained" color="primary" onClick={() => {dispatch(setShowChangePassword(true))}}>
                    Change Password
                </Button>
            </Box>
            <ChangePasswordModal />
        </div>
      );
}