import { Box, Button, Divider, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getSnapshots, createSnapshot } from 'store/snapshot.slice';
import { exportCSVFile } from 'utils/fileUtils';
import { cloneDeep } from 'lodash';
import { getCSVDenomValue, getDateTime, getDenomValue, getPercentValue, getValue } from 'utils/formatter';
import { LoadingModal } from 'components/shared/LoadingModal';

export function Snapshots(props: any) {
    const dispatch = useDispatch();

    const { snapshots } = useSelector((state: RootState) => state.snapshot);
    const [tab, setTab] = useState(0);

    const anchorColumns:  GridColDef[] = [
        {
            field: 'wallet_public_address_display', 
            headerName: 'Wallet',
            sortable: true,
            width: 130,
        },
        {
            field: 'snapshot_date',
            headerName: 'Snapshot Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDateTime(params.value)}`,
        },
        {
            field: 'deposit',
            headerName: 'Deposit',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        },
        {
            field: 'yield_reserve', 
            headerName: 'Yield Reserve',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        },
        {
            field: 'anchor_apy', 
            headerName: 'Interest APY',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getPercentValue(params.value)}`,
        },
        {
            field: 'anc_price', 
            headerName: 'ANC Price',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        }
    ];

    const massetColumns:  GridColDef[] = [
        {
            field: 'asset_symbol', 
            headerName: 'Symbol',
            sortable: true,
            width: 130,
        },
        {
            field: 'snapshot_date',
            headerName: 'Snapshot Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDateTime(params.value)}`,
        },
        {
            field: 'pool_price', 
            headerName: 'Pool Price',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        },
        {
            field: 'oracle_price',
            headerName: 'Oracle Price',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        },
        {
            field: 'premium', 
            headerName: 'Interest APY',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getPercentValue(params.value)}`,
        },
        {
            field: 'liquidity',
            headerName: 'Liquidity',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        }
    ];

    const mirrorColumns: GridColDef[] = [
        {
            field: 'wallet_public_address_display', 
            headerName: 'Wallet',
            sortable: true,
            width: 130,
        },
        {
            field: 'snapshot_date',
            headerName: 'Snapshot Date',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDateTime(params.value)}`,
        },
        {
            field: 'wallet_balance',
            headerName: 'Wallet Balance',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        },
        {
            field: 'borrowing_asset_symbol', 
            headerName: '(B) Asset Symbol',
            width: 200,
        },
        {
            field: 'borrowing_asset_price',
            headerName: '(B) Asset Price',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        },
        {
            field: 'borrowing_asset_total_price',
            headerName: '(B) Asset Total Price',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        },
        {
            field: 'borrowing_asset_shares', 
            headerName: '(B) Asset Shares',
            width: 200,
        },
        {
            field: 'borrowing_collateral_ratio', 
            headerName: '(B) Collateral Ratio',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getPercentValue(params.value)}`,
        },
        {
            field: 'borrowing_collateral_min', 
            headerName: '(B) Collateral Min',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getPercentValue(params.value)}`,
        },
        {
            field: 'borrowing_collateral_price_ust', 
            headerName: '(B) Collateral Price UST',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        },
        {
            field: 'borrowing_collateral_price_aust', 
            headerName: '(B) Collateral Price aUST',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'aUST')}`,
        },
        {
            field: 'farming_asset_symbol', 
            headerName: '(F) Asset Symbol',
            width: 200,
        },
        {
            field: 'farming_asset_shares', 
            headerName: '(F) Asset Shares',
            width: 200,
        },
        {
            field: 'farming_asset_ust', 
            headerName: '(F) Asset Value',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        },
        {
            field: 'farming_total_withdrawable_ust', 
            headerName: '(F) Total Withdrawable',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        },
        {
            field: 'farming_reward_amount', 
            headerName: '(F) Reward',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'MIR')}`,
        },
        {
            field: 'short_farming_asset_symbol', 
            headerName: '(SF) Asset Symbol',
            width: 200,
        },
        {
            field: 'short_farming_asset_shares', 
            headerName: '(SF) Asset Shares',
            width: 200,
        },
        {
            field: 'short_farming_reward_amount', 
            headerName: '(SF) Reward',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'MIR')}`,
        },
        {
            field: 'mir_price', 
            headerName: 'MIR Price',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'UST')}`,
        },
    ];

    const download = () => {
        let mirrorShots: any[] = [];
        let massetShots: any[] = [];
        let anchorShots: any[] = [];
        for(let snap of snapshots) {
            if(snap.type === 'Mirror'){
                var tmpShots = [];
                for(let snapValue of snap.snapshots) {
                    var cloneValue = cloneDeep(snapValue);
                    cloneValue['wallet_balance'] = getCSVDenomValue(snapValue['wallet_balance'], 'UST');
                    cloneValue['snapshot_date'] = getDateTime(snapValue['snapshot_date']);
                    cloneValue['borrowing_asset_price'] = getCSVDenomValue(snapValue['borrowing_asset_price'], 'UST');
                    cloneValue['borrowing_asset_total_price'] = getCSVDenomValue(snapValue['borrowing_asset_total_price'], 'UST');
                    cloneValue['borrowing_collateral_ratio'] = getPercentValue(snapValue['borrowing_collateral_ratio']);
                    cloneValue['borrowing_collateral_min'] = getPercentValue(snapValue['borrowing_collateral_min']);
                    cloneValue['borrowing_collateral_price_ust'] = getCSVDenomValue(snapValue['borrowing_collateral_price_ust'], 'UST');
                    cloneValue['borrowing_collateral_price_aust'] = getCSVDenomValue(snapValue['borrowing_collateral_price_aust'], 'aUST');
                    cloneValue['farming_asset_ust'] = getCSVDenomValue(snapValue['farming_asset_ust'], 'UST'); 
                    cloneValue['farming_total_withdrawable_ust'] = getCSVDenomValue(snapValue['farming_total_withdrawable_ust'], 'UST');
                    cloneValue['farming_reward_amount'] = getCSVDenomValue(snapValue['farming_reward_amount'], 'MIR');
                    cloneValue['short_farming_reward_amount'] = getCSVDenomValue(snapValue['short_farming_reward_amount'], 'MIR');
                    cloneValue['short_farming_asset_shares'] = getValue(snapValue['short_farming_asset_shares']);
                    cloneValue['short_farming_asset_symbol'] = getValue(snapValue['short_farming_asset_symbol']);
                    cloneValue['farming_asset_shares'] = getValue(snapValue['farming_asset_shares']);
                    cloneValue['farming_asset_symbol'] = getValue(snapValue['farming_asset_symbol']);
                    cloneValue['borrowing_asset_shares'] = getValue(snapValue['borrowing_asset_shares']);
                    cloneValue['borrowing_asset_symbol'] = getValue(snapValue['borrowing_asset_symbol']);
                    cloneValue['mir_price'] = getCSVDenomValue(snapValue['mir_price'], 'UST');
                    // cloneValue['short_farming_unlocked_amount'] = getCSVDenomValue(snapValue['short_farming_unlocked_amount'], snapValue['short_farming_unlocked_denom']);
                    // cloneValue['short_farming_locked_amount'] = getCSVDenomValue(snapValue['short_farming_locked_amount'], snapValue['short_farming_locked_denom']);

                    delete cloneValue.short_farming_unlocked_amount;
                    delete cloneValue.short_farming_locked_amount;
                    delete cloneValue.created_at;
                    delete cloneValue.updated_at;
                    delete cloneValue.id;
                    tmpShots.push(cloneValue);
                }
                mirrorShots = mirrorShots.concat(tmpShots);
            }
            else if(snap.type === 'Anchor') {
                var tmpShots = [];
                for(let snapValue of snap.snapshots) {
                    var cloneValue = cloneDeep(snapValue);
                    cloneValue['snapshot_date'] = getDateTime(snapValue['snapshot_date']);
                    cloneValue['deposit'] = getCSVDenomValue(snapValue['deposit'], 'UST');
                    cloneValue['yield_reserve'] = getCSVDenomValue(snapValue['yield_reserve'], 'UST');
                    cloneValue['anchor_apy'] = getPercentValue(snapValue['anchor_apy']);
                    cloneValue['anc_price'] = getCSVDenomValue(snapValue['anc_price'], 'UST');

                    delete cloneValue.wallet_balance;
                    delete cloneValue.created_at;
                    delete cloneValue.updated_at;
                    delete cloneValue.id;
                    tmpShots.push(cloneValue);
                }
                anchorShots = anchorShots.concat(tmpShots);
            }
            else if(snap.type === 'MAssets') {
                var tmpShots = [];
                for(let snapValue of snap.snapshots) {
                    var cloneValue = cloneDeep(snapValue);
                    cloneValue['snapshot_date'] = getDateTime(snapValue['snapshot_date']);
                    cloneValue['pool_price'] = getCSVDenomValue(snapValue['pool_price'], 'UST');
                    cloneValue['oracle_price'] = getCSVDenomValue(snapValue['oracle_price'], 'UST');
                    cloneValue['premium'] = getPercentValue(snapValue['premium']);
                    cloneValue['liquidity'] = getCSVDenomValue(snapValue['liquidity'], 'UST');


                    delete cloneValue.created_at;
                    delete cloneValue.updated_at;
                    delete cloneValue.id;
                    tmpShots.push(cloneValue);
                }
                massetShots = massetShots.concat(tmpShots);
            }
        }
        exportCSVFile(['Wallet', 'Wallet Balance', 'Denom', 'Snapshot Date', '(B) Asset Symbol', '(B) Asset Price', 'Denom', '(B) Asset Total Price', 'Denom', '(B) Asset Shares', '(B) Collateral Ratio', '(B) Collateral Min', '(B) Collateral Price UST', 'Denom', '(B) Collateral Price aUST', 'Denom', '(F) Asset Symbol', '(F) Asset Shares', '(F) Asset Value', 'Denom', '(F) Total Withdrawable', 'Denom', '(F) Reward', 'Denom', '(SF) Asset Symbol', '(SF) Asset Shares', '(SF) Reward', 'Denom', 'MIR Price', 'Denom']
        , mirrorShots, new Date().toLocaleDateString() + "_mirror_snapshot");

        exportCSVFile(['Snapshot Date', 'Asset Symbol',  'Premium', 'Pool Price', 'Denom', 'Oracle Price', 'Denom', 'Liquidity', 'Denom']
        , massetShots, new Date().toLocaleDateString() + "_masset_snapshot");

        exportCSVFile(['Wallet', 'Snapshot Date', 'Deposit', 'Denom', 'Yield Reserve', 'Denom', 'Interest APY', 'ANC Price', 'Denom' ]
        , anchorShots, new Date().toLocaleDateString() + "_anchor_snapshot");
    }

    const getColumns = (snapshot: any) => {
        if(snapshot.type === 'Mirror') {
            return mirrorColumns;
        }
        else if(snapshot.type === 'Anchor') {
            return anchorColumns;
        }
        else if(snapshot.type === 'MAssets') {
            return massetColumns;
        }
        else {
            return [];
        }
    }

    const changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    useEffect(() => {
        dispatch(getSnapshots());
    }, [dispatch]);

	return (
        <div className="App text-left">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <h1 className="header-text">Snapshots</h1>
                </Grid>
                <Grid item xs={6} style={{textAlign:'right'}}>
                    <Button style={{marginRight: '16px'}} variant="contained" color="primary" onClick={() => {dispatch(createSnapshot())}}>
                        Create Snapshot
                    </Button>
                    <Button variant="contained" color="primary" onClick={download}>
                        Download
                    </Button>
                </Grid>
            </Grid>
            <Box mb={2}>
                <Divider  />
            </Box>
            <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={changeTab}
            >
                {
                    snapshots.map((snapshot, index) => (
                        <Tab key={index} label={snapshot.type} />
                    ))
                }
            </Tabs>
            {
               snapshots.map((snapshot, index) => (
                index === tab ? <Box key={index} >
                        <h2>{snapshot.type}</h2>
                        {
                            <Paper style={{ height: 'calc(100vh - 240px)', width: '100%' }} elevation={3}>
                                <DataGrid
                                    rows={snapshot.snapshots}
                                    columns={getColumns(snapshot)}
                                    pageSize={25}
                                    disableSelectionOnClick
                                />
                            </Paper>
                        }
                    </Box>
                    :
                    null   
               ))
            }
            <LoadingModal />
        </div>
      );
}