import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL, ALERTS_URL, DASHBOARD_URL } from 'utils/constants';
import { AppThunk } from './store';


const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    activePositions: [],
    positionTotals: [],
    recentAlerts: [],
    recentBuySellTransactions: []
  },
  reducers: {
    _setActivePositions(state, action) {
        state.activePositions = action.payload;
    },
    _setPositionTotals(state, action) {
        state.positionTotals = action.payload;
    },
    _setRecentAlerts(state, action) {
        state.recentAlerts = action.payload;
    },
    _setRecentBuySellTransactions(state, action) {
        state.recentBuySellTransactions = action.payload;
    },
  }
});

const {
    _setActivePositions,
    _setPositionTotals,
    _setRecentAlerts,
    _setRecentBuySellTransactions
} = dashboardSlice.actions;

export const fetchActivePositions = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}${DASHBOARD_URL}activePositions`);
    console.log("active positions", response.data);
    dispatch(_setActivePositions(response.data));
};

export const fetchPositionTotals = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}${DASHBOARD_URL}positionTotals`);
    console.log("position totals", response.data);
    dispatch(_setPositionTotals(response.data));
};
  
export const fetchRecentAlerts = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}${DASHBOARD_URL}recentAlerts`);
    console.log("recent alerts", response.data);
    dispatch(_setRecentAlerts(response.data));
};

export const fetchRecentBuySellTransactions = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}${DASHBOARD_URL}recentBuySells`);
    console.log("recent buy sell transaction", response.data);
    dispatch(_setRecentBuySellTransactions(response.data));
};

export default dashboardSlice.reducer;