
export const dollarFormat = (value: number) => {
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return formatter.format(value);
}
   
export const numberWithCommas = (x:any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getDateTime = (snapshotDate: any) => {
    let dateVal = new Date(snapshotDate);
    return dateVal.toLocaleDateString('en-US', {timeZone: 'America/Chicago'}) + " " + dateVal.toLocaleTimeString('en-US', {timeZone: 'America/Chicago'});
}

export const getDate = (snapshotDate: any) => {
    let dateVal = new Date(snapshotDate);
    return dateVal.toLocaleDateString('en-US', {timeZone: 'America/Chicago'});
}

export const getDenomValue = (value: any, denom: string) => {
    if(value) {
        let valueRound = Math.round(value * 100) / 100;
        return numberWithCommas(valueRound) + ' ' + denom;
    }
    else {
        return '';
    }
}

export const getCSVDenomValue = (value: any, denom: string) => {
    return value ? value + ',' + denom : ',';
}

export const getValue = (value: any) => {
    return value ? value : '';
}

export const getCSVValue = (value: any) => {
    return value ? value.toString().replace(/,/g, ' | ').replace(/[\n\r]/g, '') : '';
}

export const getPercentValue = (value: any) => {
    return value ? numberWithCommas(value) + '%' : '';
}

export const getCSVPercentValue = (value: any) => {
    return value ? value + '%' : '';
}