import { Grid, Paper } from '@material-ui/core';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getDate, getDateTime, getDenomValue, getPercentValue } from 'utils/formatter';
import { LoadingModal } from 'components/shared/LoadingModal';
import { fetchPositionTotals, fetchActivePositions, fetchRecentAlerts, fetchRecentBuySellTransactions } from 'store/dashboard.slice';

export function Dashboard(props: any) {
    const dispatch = useDispatch();
    const { activePositions, positionTotals, recentAlerts, recentBuySellTransactions } = useSelector((state: RootState) => state.dashboard);

    const recentAlertColumns: GridColDef[] = [
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDateTime(params.value)}`,
        },
        {
            field: 'message',
            headerName: 'Alert',
            flex: 1
        },
    ];

    const positionTotalsColumns: GridColDef[] = [
        {
            field: 'network',
            headerName: 'Network',
            width: 130
        },
        {
            field: 'strategy',
            headerName: 'Strategy',
            width: 180
        },
        {
            field: 'dateInitiated',
            headerName: 'Initiated',
            width: 130,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDate(params.value)}`,
        },
        {
            field: 'currentNetPosition', 
            headerName: 'Cur Net Position',
            sortable: true,
            width: 170,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'USD')}`,
        },
        {
            field: 'amountInvested', 
            headerName: 'Amount Invested',
            sortable: true,
            minWidth: 180,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'USD')}`,
        },
    ];

    const activePositionsColumns: GridColDef[] = [
        {
            field: 'network',
            headerName: 'Network',
            width: 130
        },
        {
            field: 'strategy',
            headerName: 'Strategy',
            width: 180
        },
        {
            field: 'automated',
            headerName: 'Automated',
            width: 150
        },
        {
            field: 'walletAddressDisplay',
            headerName: 'Wallet',
            width: 140
        },
        {
            field: 'dateInitiated',
            headerName: 'Initiated',
            width: 130,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDate(params.value)}`,
        },
        {
            field: 'currentAPR', 
            headerName: 'Cur APR',
            sortable: true,
            width: 140,
            valueGetter: (params: GridValueGetterParams) => 
            `${getPercentValue(params.value)}`,
        },
        {
            field: 'currentTVL', 
            headerName: 'Cur TVL',
            sortable: true,
            width: 140,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'USD')}`,
        },
        {
            field: 'currentNetPosition', 
            headerName: 'Cur Net Position',
            sortable: true,
            width: 170,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'USD')}`,
        },
        {
            field: 'amountInvested', 
            headerName: 'Amount Invested',
            sortable: true,
            minWidth: 180,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'USD')}`,
        },
    ];

    const recentBuySellColumns:  GridColDef[] = [
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDateTime(params.value)}`,
        },
        {
            field: 'wallet_address_display', 
            headerName: 'Wallet',
            sortable: true,
            width: 120,
        },
        {
            field: 'transaction_message', 
            headerName: 'Message',
            sortable: true,
            flex: 1,
        }
    ];

    useEffect(() => {
        dispatch(fetchPositionTotals());
        dispatch(fetchActivePositions());
        dispatch(fetchRecentAlerts());
        dispatch(fetchRecentBuySellTransactions());
    }, [dispatch]);

	return (
        <div className="App text-left">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h1 className="header-text">Dashboard</h1>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Paper style={{ height: '210px', width: '100%',marginBottom: '16px', padding: '8px 16px 16px 16px' }} elevation={3}>
                        <h2>Total Positions</h2>
                        <DataGrid
                            style={{height: '125px'}}
                            hideFooter={true}
                            rows={positionTotals}
                            columns={positionTotalsColumns}
                            pageSize={2}
                            disableSelectionOnClick
                            getRowId={(row) => row.dateInitiated}
                        />
                    </Paper>
                    <Paper style={{ height: '500px', width: '100%',  padding: '8px 16px 16px 16px' }} elevation={3}>
                        <h2>Active Positions</h2>
                        <DataGrid
                             style={{height: '418px'}}
                            getRowId={(row) => row.walletAddressDisplay}
                            rows={activePositions}
                            columns={activePositionsColumns}
                            pageSize={10}
                            disableSelectionOnClick
                        />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper style={{ height: '400px', width: '100%', marginBottom: '16px', padding: '8px 16px 16px 16px' }} elevation={3}>
                        <h2>Recent Buy/Sell Transactions</h2>
                        <DataGrid
                            style={{height: '318px'}}
                            hideFooter={true}
                            rows={recentBuySellTransactions}
                            columns={recentBuySellColumns}
                            pageSize={10}
                            disableSelectionOnClick
                        />
                    </Paper>
                    <Paper style={{ height: '400px', width: '100%', padding: '8px 16px 16px 16px' }} elevation={3}>
                        <h2>Recent Alerts</h2>
                        <DataGrid
                            style={{height: '318px'}}
                            hideFooter={true}
                            rowsPerPageOptions={[]}
                            rows={recentAlerts}
                            columns={recentAlertColumns}
                            pageSize={10}
                            disableSelectionOnClick
                        />
                    </Paper>
                    
                </Grid>
            </Grid>
           
                    
            <LoadingModal />
        </div>
      );
}