import { Button, TextField } from '@material-ui/core';
import SimpleModal from 'components/shared/SimpleModal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { reencryptWallets, setShowChangeDecryptionKeyModal } from 'store/main.slice';

export function ChangeDecryptionKeyModal(props: any) {
  const dispatch = useDispatch();

  const [ localOldKey, setLocalOldKey] = useState('');
  const [ localNewKey, setLocalNewKey] = useState('');

  const { showChangeDecryptionKeyModal } = useSelector((state: RootState) => state.main);

  const submitDecryptionKey = () => {
    dispatch(reencryptWallets(localOldKey, localNewKey));
  }

  const body = (
      <div>
        <h2 id="simple-modal-title">Change Decryption Key</h2>
        <p>Changing the decryption key will re-encrypt all wallets with the new decryption key.</p>
        <TextField
          style={{width: '100%', marginBottom: '16px'}}
          label="Old Key (min 6 chars)"
          type="password"
          variant="outlined"
          onChange={(e: any) => {setLocalOldKey(e.target.value)}}
        />
         <TextField
          style={{width: '100%', marginBottom: '16px'}}
          label="New Key (min 6 chars)"
          type="password"
          variant="outlined"
          onChange={(e: any) => {setLocalNewKey(e.target.value)}}
        />
        <div className="text-right">
            <Button className="mr-2" variant="contained" color="default" onClick={() => {dispatch(setShowChangeDecryptionKeyModal(false))}}>
                Cancel
            </Button>
            <Button disabled={localNewKey.length < 6} variant="contained" color="primary" onClick={submitDecryptionKey}>
                Submit
            </Button>
        </div>
      </div>
  );

	return (
        <SimpleModal open={showChangeDecryptionKeyModal} body={body} />
      );
}