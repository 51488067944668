import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Tab, Tabs } from '@material-ui/core';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { exportCSVFile } from 'utils/fileUtils';
import { cloneDeep } from 'lodash';
import { getCSVPercentValue, getCSVValue, getDateTime, getDenomValue, getPercentValue } from 'utils/formatter';
import { LoadingModal } from 'components/shared/LoadingModal';
import { getMangoTransactions, setSelectedFilterLog } from 'store/logs.slice';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { logFilters } from 'utils/constants';

export function SolanaLog(props: any) {
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(17);
    const { mangoTransactions, selectedFilterLog } = useSelector((state: RootState) => state.logs);
    const [tab, setTab] = useState(0);
    let tmp = new Date();
    tmp.setDate(tmp.getDate() - 7);
    const [startDate, setStartDate] = useState(tmp);
    const [endDate, setEndDate] = useState(new Date());

    const logsColumns:  GridColDef[] = [
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 180,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDateTime(params.value)}`,
        },
        {
            field: 'wallet_address_display', 
            headerName: 'Wallet',
            sortable: true,
            width: 120,
        },
        {
            field: 'transaction_message', 
            headerName: 'Message',
            sortable: true,
            width: 400,
        },
        {
            field: 'francium_type', 
            headerName: 'Francium',
            sortable: true,
            width: 150,
        },
        {
            field: 'francium_value', 
            headerName: 'FValue',
            sortable: true,
            width: 120,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'SOL')}`,
        },
        {
            field: 'mango_type', 
            headerName: 'Mango',
            sortable: true,
            width: 120,
        },
        {
            field: 'mango_value', 
            headerName: 'MValue',
            sortable: true,
            width: 125,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'SOL')}`,
        },
        {
            field: 'asset_type', 
            headerName: 'Asset',
            sortable: true,
            width: 120
        },
        {
            field: 'previous_asset_price', 
            headerName: 'Prev Price',
            sortable: true,
            width: 150,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'USD')}`,
        },
        {
            field: 'current_asset_price', 
            headerName: 'Cur Price',
            sortable: true,
            width: 150,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'USD')}`,
        },
        {
            field: 'threshold', 
            headerName: 'Threshold',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => 
            `${getPercentValue(params.value)}`,
        },
        {
            field: 'percent_diff', 
            headerName: 'Diff',
            width: 120,
            valueGetter: (params: GridValueGetterParams) => 
            `${getPercentValue(params.value)}`,
        },
        {
            field: 'liquidity_pool_apy', 
            headerName: 'LP APY',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => 
            `${getPercentValue(params.value)}`,
        },
        {
            field: 'liquidity_pool_apr', 
            headerName: 'LP APR',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => 
            `${getPercentValue(params.value)}`,
        },
        {
            field: 'borrow_rate', 
            headerName: 'Borrow Rate',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => 
            `${getPercentValue(params.value)}`,
        },
        {
            field: 'borrow_equity', 
            headerName: 'Borrow Equity',
            sortable: true,
            width: 150,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'USD')}`,
        },
        {
            field: 'total_equity', 
            headerName: 'Francium Equity',
            sortable: true,
            width: 150,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'USD')}`,
        },
        {
            field: 'net_equity', 
            headerName: 'Net Equity',
            sortable: true,
            width: 150,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'USD')}`,
        },
        {
            field: 'total_value_locked', 
            headerName: 'TVL',
            sortable: true,
            width: 150,
            valueGetter: (params: GridValueGetterParams) => 
            `${getDenomValue(params.value, 'USD')}`,
        },
    ];

    const download = () => {
        let tmpLogs: any[] = [];
      
        for(let snapValue of mangoTransactions) {
            var cloneValue = cloneDeep(snapValue);
            cloneValue['wallet_address_display'] = getCSVValue(snapValue['wallet_address_display']);
            cloneValue['transaction_message'] = getCSVValue(snapValue['transaction_message']);
            cloneValue['created_at'] = getDateTime(snapValue['created_at']);
            cloneValue['francium_type'] = getCSVValue(snapValue['francium_type']);
            cloneValue['francium_value'] = getCSVValue(snapValue['francium_value']) + ' SOL';
            cloneValue['mango_type'] = getCSVValue(snapValue['mango_type']);
            cloneValue['mango_value'] = getCSVValue(snapValue['mango_value']) + ' SOL';
            cloneValue['total_equity'] = getCSVValue(snapValue['total_equity']) + 'USD';
            cloneValue['asset_type'] = getCSVValue(snapValue['asset_type']);
            cloneValue['previous_asset_price'] = getCSVValue(snapValue['previous_asset_price']) + ' USD';
            cloneValue['current_asset_price'] = getCSVValue(snapValue['current_asset_price']) + ' USD';
            cloneValue['threshold'] = getCSVPercentValue(snapValue['threshold']);
            cloneValue['percent_diff'] = getCSVPercentValue(snapValue['percent_diff']);
            cloneValue['liquidity_pool_apy'] = getCSVPercentValue(getCSVValue(snapValue['liquidity_pool_apy']));
            cloneValue['borrow_rate'] = getCSVPercentValue(snapValue['borrow_rate']);
            cloneValue['borrow_equity'] = getCSVValue(snapValue['borrow_equity']) + 'USD';
            cloneValue['net_equity'] = getCSVValue(snapValue['net_equity']) + 'USD';
            cloneValue['total_value_locked'] = getCSVValue(snapValue['total_value_locked']) + 'USD';
            cloneValue['liquidity_pool_apr'] = getCSVPercentValue(getCSVValue(snapValue['liquidity_pool_apr']));

            delete cloneValue.updated_at;
            delete cloneValue.id;
            delete cloneValue.wallet_total
            
            tmpLogs.push(cloneValue);
        }

        exportCSVFile(['Wallet', 'Message', 'Francium Type', 'Francium Value', 'Mango Type', 'Mango Value', 'Francium Equity', 'Asset Type', 'Previous Price', 'Current Price', 'Threshold', 'Diff', 'LP APY', 'Created At', 'Borrow Rate', 'Borrow Equity', 'Net Equity', 'Total Value Locked', 'LP APR' ]
        , tmpLogs, new Date().toLocaleDateString() + "_logs");
    }

    const getColumns = (logs: any) => {
        return logsColumns;
    }

    const changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    const handleEndDateChange = (date: Date) => {
        setEndDate(date);
        dispatch(getMangoTransactions(startDate, date));
    }

    const handleStartDateChange = (date: Date) => {
        setStartDate(date);
        dispatch(getMangoTransactions(date, endDate));
    }

    const handleWindowResize = () => {
        let pSize = 80;
        if(window.innerHeight > 900 && window.innerHeight < 1200) {
            pSize = 90;
        }
        else if(window.innerHeight <= 900) {
            pSize = 100;
        }

        console.log(pSize);
        const pageSize = parseInt((window.innerHeight / pSize) + "");
        console.log('calculated pageSize: ' + pageSize);
        setPageSize(pageSize);
    }

    useEffect(() => {
        dispatch(getMangoTransactions(startDate, endDate))
    }, [selectedFilterLog, dispatch]);

    useEffect(() => {
        dispatch(getMangoTransactions(startDate, endDate));
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize, false);
    }, [dispatch]);

	return (
        <div className="App text-left">
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <h2 className="header-text">Transaction Logs</h2>
                    <FormControl style={{marginRight: '16px', minWidth: '200px'}}>
                        <InputLabel id="demo-simple-select-label">Filter Message</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select Fund"
                            value={selectedFilterLog}
                            onChange={(e) => {dispatch(setSelectedFilterLog(e.target.value as string))}}
                            >
                            <MenuItem value={"default"}>Show All</MenuItem>
                            {
                                logFilters.map((filter, index) => (
                                    <MenuItem key={index} value={filter.Name}>{filter.Name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => {
                                handleStartDateChange(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newValue) => {
                                handleEndDateChange(newValue);
                            }}
                            renderInput={(params) => <TextField style={{marginLeft: '16px'}} {...params} />}
                        />
                    </LocalizationProvider>
                    
                </Grid>
                <Grid item xs={3} style={{textAlign:'right'}}>
                    <Button variant="contained" color="primary" onClick={download}>
                        Download
                    </Button>
                </Grid>
            </Grid>
            <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={changeTab}
            >
                {
                    <Tab label={'SOL'} />
                }
            </Tabs>
            {
                <Box >
                    {
                        <Paper style={{ height: 'calc(100vh - 340px)', width: '100%' }} elevation={3}>
                            <DataGrid
                                rows={mangoTransactions}
                                columns={getColumns(mangoTransactions)}
                                pageSize={pageSize}
                                disableSelectionOnClick
                            />
                        </Paper>
                    }
                </Box>
            }
            <LoadingModal />
        </div>
      );
}