import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL, ALERTS_URL } from 'utils/constants';
import { setSuccess } from './notification.slice';
import { AppThunk } from './store';


const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    alerts: []
  },
  reducers: {
    _setAlerts(state, action) {
      state.alerts = action.payload;
    }
  }
});

const {
    _setAlerts
} = alertsSlice.actions;


export const getAlerts = (type: string): AppThunk => async (dispatch) => {
  const response = await axios.post(`${API_URL}${ALERTS_URL}${type}`);
  console.log("alerts", response.data.alerts);
  dispatch(_setAlerts(response.data.alerts));
};

export const updateAlert = (alerts: any[]): AppThunk => async (dispatch) => {
  const response = await axios.post(`${API_URL}${ALERTS_URL}update`, {alerts: alerts});
  dispatch(_setAlerts(response.data.alerts));
  dispatch(setSuccess("Success!"));
};

export default alertsSlice.reducer;