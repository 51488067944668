import { Button, createStyles, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, OutlinedInput, Paper, Theme } from '@material-ui/core';
import { Person, Visibility, VisibilityOff } from '@material-ui/icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setError } from 'store/notification.slice';
import { attemptLogin, register } from 'store/auth.slice';
import { validatePassword } from 'utils/validator';
import {useNavigate} from 'react-router-dom';

interface State {
  username: string;
  password: string;
  showPassword: boolean;
  showRegister: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: '10%'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginLeft: '50px'
    },
    containerDiv: {
      width: '500px',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    offsetMenu: {
      marginLeft: '0px'
    },
    formWidth: {
      width: '100%',
      marginBottom: '20px'
    }
  }),
);

export function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, setLogin] = useState<State>({
    username: '',
    password: '',
    showPassword: false,
    showRegister: false
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setLogin({ ...login, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setLogin({
      ...login,
      showPassword: !login.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // const handleClickShowRegister = () => {
  //   setLogin({
  //     ...login,
  //     showRegister: !login.showRegister,
  //   });
  // };

  const loginClick = async () => {
    dispatch(attemptLogin(login, navigate));
  };
  const isInvalid = () => {
    var alert = '';
    if(!login.username || login.username.length <= 7) {
      alert += 'Invalid username. ';
    }

    if(!validatePassword(login.password)) {
      alert += 'Invalid password strength. ';
    }

    return alert ? alert : null;
  };

  const registerClick = async () => {
    var invalid = isInvalid();
    if(!invalid) {
      dispatch(register(login, navigate));
    }
    else {
      dispatch(setError(invalid));
    }
  }

  const cancelRegisterClick = async () => {
    setLogin({
      ...login,
      username: '',
      password: '',
      showRegister: !login.showRegister,
    });
  };

  const keyDownHandler = (event: any) => {
    if(event.key === 'Enter'){
      loginClick();
    }
  }

  const classes = useStyles();

	return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.offsetMenu}>
          <div className={classes.containerDiv}>
            <Paper className={classes.paper} elevation={3}  onKeyDown={keyDownHandler}>
              <div>
                <h1>Crypto Exploration</h1>
                <FormControl variant="outlined" className={classes.formWidth}>
                  <InputLabel required htmlFor="outlined-adornment-username">Username</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-username"
                    type={'text'}
                    value={login.username}
                    onChange={handleChange('username')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex="-1"
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          {<Person />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Username"
                  />
                </FormControl>
                <FormControl variant="outlined" className={classes.formWidth}>
                  <InputLabel required htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={login.showPassword ? 'text' : 'password'}
                    value={login.password}
                    onChange={handleChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex="-1"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {login.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <div className={classes.formWidth + ' text-align-right'}>
                  {
                    !login.showRegister ? <Button variant="contained" color="primary" className="button-min-width" onClick={loginClick}>Login</Button>
                    :
                    <div>
                      <Button variant="contained" color="primary" className="button-min-width" onClick={registerClick}>Register</Button>
                      <Button variant="contained" className="button-min-width" onClick={cancelRegisterClick}>Cancel</Button>
                    </div>
                  }
                </div>
                {/* {
                  !login.showRegister ?
                  <div className={classes.formWidth + ' mb-0 mt-40'} >
                      <div className={classes.formWidth + ' text-align-right mb-0'}>
                        <Link href="#" underline="none" onClick={handleClickShowRegister}>
                          {'No Account? Register'}
                        </Link>
                      </div>
                  </div>
                  :
                  null
                } */}
              </div>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </div>
      );
}