import { Box, Button, Divider, Typography } from '@material-ui/core';
import SimpleModal from 'components/shared/SimpleModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setShowConfirm } from 'store/main.slice';

export function ConfirmModal(props: any) {
  const dispatch = useDispatch();

  const { showConfirm } = useSelector((state: RootState) => state.main);

  const body = (
      <div>
        <h2 id="simple-modal-title">{props.title}</h2>
        <Box mb={2}>
            <Divider  />
        </Box>
        <Typography>{props.message}</Typography>
        <div className="text-right" style={{marginTop: '24px'}}>
            <Button className="mr-2" variant="contained" color="default" onClick={() => {dispatch(setShowConfirm(false))}}>
                Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => {props.confirm(); dispatch(setShowConfirm(false))}}>
                Submit
            </Button>
        </div>
      </div>
  );

	return (
        <SimpleModal open={showConfirm} body={body} />
      );
}