import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';

const timeout = 5000;
const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    error: '',
    info: '',
    success: '',
    warning: '',
    user: null,
  },
  reducers: {
    _setError(state, action) {
      state.error = action.payload;
    },
    _setInfo(state, action) {
      state.info = action.payload;
    },
    _setSuccess(state, action) {
      state.success = action.payload;
    },
    _setWarning(state, action) {
      state.warning = action.payload;
    },
    _setUser(state, action) {
      state.user = action.payload;
    }
  }
});

const {
    _setError,
    _setInfo,
    _setSuccess,
    _setWarning,
    _setUser } = notificationSlice.actions;

export const setError = (value: string): AppThunk => (dispatch) => {
    dispatch(_setError(value));
    setTimeout(() => {
        dispatch(_setError(null));
    }, timeout);
};

export const setInfo = (value: string): AppThunk => (dispatch) => {
    dispatch(_setInfo(value));
    setTimeout(() => {
        dispatch(_setInfo(null));
    }, timeout);
};

export const setSuccess = (value: string): AppThunk => (dispatch) => {
    dispatch(_setSuccess(value));
    setTimeout(() => {
        dispatch(_setSuccess(null));
    }, timeout);
};

export const setWarning = (value: string): AppThunk => (dispatch) => {
    dispatch(_setWarning(value));
    setTimeout(() => {
        dispatch(_setWarning(null));
    }, timeout);
};
export const setUser = (value: any): AppThunk => async (dispatch) => {
  dispatch(_setUser(value));
};

export default notificationSlice.reducer;