import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { ChangeDecryptionKeyModal } from 'components/shared/ChangeDecryptionKeyModal';
import { DecryptionKeyModal } from 'components/shared/DecryptionKeyModal';
import SimpleModal from 'components/shared/SimpleModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setError } from 'store/notification.slice';
import { setShowDecryptionKey } from 'store/auth.slice';
import { setShowConfirm } from 'store/main.slice';
import { setShowInvestorModal, setShowFundModal, deleteFund, deleteInvestor, deleteWallets, getFunds, getInvestors, addWallet, setShowAddWalletModal, getWalletsByInvestor, setSelectedFund, setSelectedInvestor, setShowManageFundInvestorsModal, getWalletsByFund, getWallets, } from 'store/fundManagement.slice';
import { FundModal } from 'components/fund-management/FundModal';
import { InvestorModal } from 'components/fund-management/InvestorModal';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { getDateTime } from 'utils/formatter';
import { ConfirmModal } from 'components/shared/ConfirmModal';
import { FundManageInvestors } from './FundManageInvestors';

export function FundManagement(props: any) {
  const dispatch = useDispatch();

  const { selectedFund, selectedInvestor, showAddWalletModal, funds, investorWallets, fundWallets, investors, fundWalletColumns } = useSelector((state: RootState) => state.fundManagement);
  const { decryptionKey } = useSelector((state: RootState) => state.auth);
  const [password, setPassword] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [selectedWalletIds, setSelectedWalletIds] = useState([]);
  const paginationSize = 14;

  const walletColumns: GridColDef[] = [
    {
        field: 'public_address_display', 
        headerName: 'Wallet',
        sortable: true,
        width: 130,
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        width: 180,
        valueGetter: (params: GridValueGetterParams) => 
        `${getDateTime(params.value)}`,
    }
  ];

  const submitAddWallet = () => {
    if(password && privateKey) {
        dispatch(addWallet(selectedInvestor.id, password, privateKey, decryptionKey));
        setPassword('');
        setPrivateKey('');
    }
    else {
        dispatch(setError("Please enter a password and private key."));
    }
  };

  const body = (
      <div>
        <h2 id="simple-modal-title">Add New Wallet</h2>
        <TextField
          style={{width: '100%', marginBottom: '16px'}}
          label="Password"
          type="password"
          variant="outlined"
          onChange={(e: any) => {setPassword(e.target.value)}}
        />
        <TextField
          style={{width: '100%', marginBottom: '16px'}}
          label="Private Key"
          type="password"
          variant="outlined"
          onChange={(e: any) => {setPrivateKey(e.target.value)}}
        />
        <div className="text-right">
            <Button className="mr-2" variant="contained" color="default" onClick={() => {dispatch(setShowAddWalletModal(false))}}>
                Cancel
            </Button>
            <Button disabled={!password || !privateKey} variant="contained" color="primary" onClick={() => {submitAddWallet()}}>
                Submit
            </Button>
        </div>
      </div>
  );

  const addWalletModal = () => {
    if(selectedInvestor.id) {
        if(decryptionKey) {
            dispatch(setShowAddWalletModal(true));
        }
        else {
            dispatch(setShowDecryptionKey(true));
        }
    }
    else {
        dispatch(setError("Please select an investor to add a wallet."));
    }
  }

  const changeSelectedFund = (e: any) => {
    dispatch(setSelectedFund(e.target.value));
    dispatch(getWalletsByFund(e.target.value.id));
  }

  const confirm = () => {
      if(confirmMessage.indexOf('fund') > -1) {
        dispatch(deleteFund());
      }
      else if(confirmMessage.indexOf('investor') > -1) {
        dispatch(deleteInvestor());
      }
      else if(confirmMessage.indexOf('wallets?') > -1) {
          dispatch(deleteWallets(selectedWalletIds));
      }
  }

  const changeSelectedInvestor = (e: any) => {
    dispatch(setSelectedInvestor(e.target.value));
    dispatch(getWalletsByInvestor(e.target.value.id));
  }

  const confirmDeleteFund = () => {
    if(selectedFund.id) {
        setConfirmMessage("Are you sure you want to delete this fund?  Deleting this fund will not delete investors or wallets.");
        dispatch(setShowConfirm(true));
    }
    else {
        dispatch(setError("Please select a fund to delete."));
    }
  }

  const confirmDeleteInvestor = () => {
    if(selectedFund.id) {
        setConfirmMessage("Are you sure you want to delete this investor? Deleting this investor will delete all wallets.");
        dispatch(setShowConfirm(true));
    }
    else {
        dispatch(setError("Please select an investor to delete."));
    }
  }

  const confirmDeleteWallets = () => {
    if(selectedWalletIds.length > 0) {
        setConfirmMessage("Are you sure you want to delete the selected wallets?");
        dispatch(setShowConfirm(true));
    }
    else {
        dispatch(setError("No wallets selected."));
    }
  }

  const editFund = (e: any) => {
    if(selectedFund.id) {
        dispatch(setShowFundModal(true));
    }
    else {
        dispatch(setError("Please select a fund before editing."));
    }
  }

  const editInvestor = (e: any) => {
    if(selectedInvestor.id) {
        dispatch(setShowInvestorModal(true));
    }
    else {
        dispatch(setError("Please select an investor before editing."));
    }
  }

  const manageFundInvestors = () => {
    if(selectedFund.id) {
        dispatch(getWallets());
        dispatch(setShowManageFundInvestorsModal(true));
    }
    else {
        dispatch(setError("Please select a fund before managing investors."));
    }
  };

  const newFund = (e: any) => {
      dispatch(setSelectedFund({}));
      dispatch(setShowFundModal(true));
  }

  const newInvestor = (e: any) => {
    dispatch(setSelectedInvestor({}));
    dispatch(setShowInvestorModal(true));
  }

//   const localChangeDecryptionKeyModal = () => {
//     dispatch(setShowChangeDecryptionKeyModal(true));
//   }

  const investorSelectionModelChange = (e: any) => {
    setSelectedWalletIds(e);
  };

    useEffect(() => {
        dispatch(getFunds());
        dispatch(getInvestors());
        dispatch(getWallets());
    }, [dispatch]);

	return (
        <div className="App text-left">
            <h1 className="header-text">Fund Management</h1>
            <Box mb={2}>
                <Divider  />
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper style={{marginTop: '16px', padding: '16px 16px 16px 16px' }} elevation={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <h2 className="header-text">Funds</h2>
                            </Grid>
                            <Grid item xs={6} style={{textAlign:'right'}}>
                                <Button variant="contained" color="primary" onClick={newFund}>
                                    New Fund
                                </Button>
                            </Grid>
                        </Grid>
                        <Box mb={2}>
                            <Divider  />
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Fund</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Fund"
                                    value={selectedFund}
                                    onChange={changeSelectedFund}
                                    >
                                    {
                                        funds.map((fund, index) => (
                                            <MenuItem key={index} value={fund}>{fund.name}</MenuItem>
                                        ))
                                    }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} style={{textAlign:'right', paddingTop: '36px'}}>
                                <Edit style={{marginRight: '16px', cursor: 'pointer'}} onClick={editFund} />
                                <Delete style={{cursor: 'pointer'}} onClick={confirmDeleteFund}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{paddingTop: '24px'}}>
                            <Grid item xs={6}>
                                <h2 className="header-text">Investors</h2>
                            </Grid>
                            <Grid item xs={6} style={{textAlign:'right'}}>
                                <Button variant="contained" color="primary" onClick={manageFundInvestors}>
                                    Manage Fund Investors
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <DataGrid
                                    style={{ height: 'calc(100vh - 450px)' }}
                                    rows={fundWallets}
                                    columns={fundWalletColumns}
                                    pageSize={paginationSize}
                                    disableSelectionOnClick
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper style={{marginTop: '16px', padding: '16px 16px 16px 16px' }} elevation={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <h2 className="header-text">Investors</h2>
                            </Grid>
                            <Grid item xs={6} style={{textAlign:'right'}}>
                                <Button variant="contained" color="primary" onClick={newInvestor}>
                                    New Investor
                                </Button>
                            </Grid>
                        </Grid>
                        <Box mb={2}>
                            <Divider  />
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Investor</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Investor"
                                        value={selectedInvestor}
                                        onChange={changeSelectedInvestor}
                                    >
                                    {
                                        investors.map((investor, index) => (
                                            <MenuItem key={index} value={investor}>{investor.name}</MenuItem>
                                        ))
                                    }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} style={{textAlign:'right', paddingTop: '36px'}}>
                                <Edit style={{marginRight: '16px', cursor: 'pointer'}} onClick={editInvestor}/>
                                <Delete style={{cursor: 'pointer'}} onClick={confirmDeleteInvestor}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{paddingTop: '24px'}}>
                            <Grid item xs={6}>
                                <h2 className="header-text">Wallets</h2>
                            </Grid>
                            <Grid item xs={6} style={{textAlign:'right'}}>
                                <Button variant="contained" color="primary" onClick={addWalletModal}>
                                    Add Wallet
                                </Button>
                                <Button variant="contained" color="primary" onClick={confirmDeleteWallets} style={{marginLeft: '16px'}}>
                                    Delete Wallet
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <DataGrid
                                    style={{ height: 'calc(100vh - 450px)' }}
                                    rows={investorWallets}
                                    columns={walletColumns}
                                    pageSize={paginationSize}
                                    disableSelectionOnClick
                                    checkboxSelection={true}
                                    onSelectionModelChange={investorSelectionModelChange}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <SimpleModal open={showAddWalletModal} body={body} />
            <DecryptionKeyModal postSubmit={() => {dispatch(setShowAddWalletModal(true))}} />
            <ChangeDecryptionKeyModal />
            <FundModal />
            <InvestorModal />
            <FundManageInvestors />
            <ConfirmModal title="Confirm Delete" message={confirmMessage} confirm={confirm} />
        </div>
      );
}