export const API_URL = `${process.env.REACT_APP_SERVER_URL}/`;
export const AUTH_URL = 'auth/';
export const USERS_URL = 'users/';
export const PROTOCOLS_URL = 'protocols/';
export const WALLETS_URL = 'wallets/';
export const SNAPSHOTS_URL = 'snapshots/';
export const LOGS_URL = 'logs/';
export const MANGO_TRANSACTIONS_URL = "mangotransactions/";
export const ALERTS_URL = 'alerts/';
export const DASHBOARD_URL = 'dashboard/';
export const SETTINGS_URL = 'settings/';
export const INVESTORS_URL = 'investors/';
export const FUNDS_URL = 'funds/';
export const STRATEGY_SOLANA = "Mango/Francium/Solana";
export const STRATEGY_ANCHOR = "Anchor";
export const FILTER_ALERT = "Alert";
export const FILTER_HIDE_NOACTION = "Hide No Action";

//states
export const states = [{Name: ''}, {Name:'AL'},{Name:'AK'},{Name:'AS'},{Name:'AZ'},{Name:'AR'},{Name:'CA'},{Name:'CO'},{Name:'CT'},{Name:'DE'},{Name:'DC'},{Name:'FL'},{Name:'GA'},
{Name:'GU'},{Name:'HI'},{Name:'ID'},{Name:'IL'},{Name:'IN'},{Name:'IA'},{Name:'KS'},{Name:'KY'},{Name:'LA'},{Name:'ME'},{Name:'MD'},{Name:'MA'},{Name:'MI'},{Name:'MN'},
{Name:'MS'},{Name:'MO'},{Name:'MT'},{Name:'NE'},{Name:'NV'},{Name:'NH'},{Name:'NJ'},{Name:'NM'},{Name:'NY'},{Name:'NC'},{Name:'ND'},{Name:'MP'},{Name:'OH'},{Name:'OK'},
{Name:'OR'},{Name:'PA'},{Name:'PR'},{Name:'RI'},{Name:'SC'},{Name:'SD'},{Name:'TN'},{Name:'TX'},{Name:'UT'},{Name:'VT'},{Name:'VA'},{Name:'VI'},{Name:'WA'},{Name:'WV'},
{Name:'WI'},{Name:'WY'}];

export const fullStates = [{Name: ''}, {Name: 'Alabama'},{Name: 'Alaska'},{Name: 'Arizona'},{Name: 'Arkansas'},{Name: 'California'},{Name: 'Colorado'},
{Name: 'Connecticut'},{Name: 'Delaware'},{Name: 'Florida'},{Name: 'Georgia'},{Name: 'Hawaii'},{Name: 'Idaho'},{Name: 'Illinois'},
{Name: 'Indiana'},{Name: 'Iowa'},{Name: 'Kansas'},{Name: 'Kentucky'},{Name: 'Louisiana'},{Name: 'Maine'},{Name: 'Maryland'},{Name: 'Massachusetts'},
{Name: 'Michigan'},{Name: 'Minnesota'},{Name: 'Mississippi'},{Name: 'Missouri'},{Name: 'Montana'},{Name: 'Nebraska'},{Name: 'Nevada'},{Name: 'New Hampshire'},
{Name: 'New Jersey'},{Name: 'New Mexico'},{Name: 'New York'},{Name: 'North Carolina'},{Name: 'North Dakota'},{Name: 'Ohio'},{Name: 'Oklahoma'},{Name: 'Oregon'},{Name: 'Pennsylvania'},
{Name: 'Rhode Island'},{Name: 'South Carolina'},{Name: 'South Dakota'},{Name: 'Tennessee'},{Name: 'Texas'},{Name: 'Utah'},{Name: 'Vermont'},{Name: 'Virginia'},{Name: 'Washington'},
{Name: 'West Virginia'},{Name: 'Wisconsin'},{Name: 'Wyoming'}];

//months
export const months = [{Name:''}, {Name:'January'},{Name:'February'},{Name:'March'},{Name:'April'},{Name:'May'},{Name:'June'},{Name:'July'},{Name:'August'},{Name:'September'},{Name:'October'},{Name:'November'},{Name:'December'}];

//strategies
export const strategies = [{Name: STRATEGY_SOLANA}, {Name: STRATEGY_ANCHOR}];

export const logFilters = [{Name: FILTER_ALERT}, {Name: FILTER_HIDE_NOACTION}];