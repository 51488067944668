import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL, AUTH_URL, PROTOCOLS_URL, WALLETS_URL } from 'utils/constants';
import { setError, setSuccess } from './notification.slice';
import { setDecryptionKey } from './auth.slice';
import { AppThunk } from './store';


const mainSlice = createSlice({
  name: 'main',
  initialState: {
    mirrorProtocolData: [],
    showChangeDecryptionKeyModal: false,
    showChangePassword: false,
    showConfirm: false,
    showLoading: false,
  },
  reducers: {
    _setMirrorProtocolData(state, action) {
      state.mirrorProtocolData = action.payload;
    },
    _setShowChangeDecryptionKeyModal(state, action) {
      state.showChangeDecryptionKeyModal = action.payload;
    },
    _setShowChangePassword(state, action) {
      state.showChangePassword = action.payload;
    },
    _setShowConfirm(state, action) {
      state.showConfirm = action.payload;
    },
    _setShowLoading(state, action) {
      state.showLoading = action.payload;
    }
  }
});

const {
  _setMirrorProtocolData,
  _setShowChangeDecryptionKeyModal,
  _setShowChangePassword,
  _setShowConfirm,
  _setShowLoading,
} = mainSlice.actions;

export const reencryptWallets = (oldKey: string, newKey: string): AppThunk => async (dispatch) => {
  var response = await axios.post(`${API_URL}${WALLETS_URL}reencrypt`, {oldKey: oldKey, newKey: newKey});
  if(response.data.error) {
    dispatch(setError(response.data.error));
  }
  else {
    dispatch(_setShowChangeDecryptionKeyModal(false));
    dispatch(setDecryptionKey(newKey));
    dispatch(setSuccess("Decryption key changed!"));
  }
};

export const setNewPassword = (oldPassword: string, newPassword: string): AppThunk => async (dispatch) => {
  var response = await axios.post(`${API_URL}${AUTH_URL}changePassword`, {oldPassword: oldPassword, newPassword: newPassword});
  if(response.data.error) {
    dispatch(setError(response.data.error));
  }
  else {
    dispatch(_setShowChangePassword(false));
    dispatch(setSuccess("Password changed!"));
  }
};

export const setShowChangeDecryptionKeyModal = (value: boolean): AppThunk => async (dispatch) => {
  dispatch(_setShowChangeDecryptionKeyModal(value));
};

export const setShowChangePassword = (value: boolean): AppThunk => async (dispatch) => {
  dispatch(_setShowChangePassword(value));
};

export const setShowConfirm = (value: boolean): AppThunk => async (dispatch) => {
  dispatch(_setShowConfirm(value));
};

export const setShowLoading = (value: boolean): AppThunk => async (dispatch) => {
  dispatch(_setShowLoading(value));
};

export const getAnchorProtocol = (): AppThunk => async (dispatch,  getState) => {
  var decryptionKey =  getState().auth.decryptionKey;
  const response = await axios.post(`${API_URL}${PROTOCOLS_URL}terra`, {decryptionKey: decryptionKey}); 
  console.log(response.data);
};

export const getMirrorProtocol = (): AppThunk => async (dispatch,  getState) => {
  dispatch(setShowLoading(true));
  var decryptionKey =  getState().auth.decryptionKey;
  const response = await axios.post(`${API_URL}${PROTOCOLS_URL}mirror`, {decryptionKey: decryptionKey});
  console.log(response);
  dispatch(setShowLoading(false));
  if(response.data.error) {
    dispatch(setError(response.data.error));
  }
  else {
    console.log('mirror data', response.data.result);
    dispatch(_setMirrorProtocolData(response.data.result));
  }
};

export default mainSlice.reducer;