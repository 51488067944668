import SimpleModal from 'components/shared/SimpleModal';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

export function LoadingModal(props: any) {
    const { showLoading } = useSelector((state: RootState) => state.main);

  const body = (
     <h3 style={{textAlign: 'center'}}>{props.message ? props.message : 'Loading...'}</h3>
  );

	return (
        <SimpleModal open={showLoading} body={body} />
      );
}