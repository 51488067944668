import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styleWrapper = (width: string) => {
  return makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        position: 'absolute',
        width: width ? width : 400,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        borderRadius: '5px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
    }),
  );
}



export default function SimpleModal(props: any) {
  const classes = styleWrapper(props.width)();
 
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
           {props.body}
        </div>
      </Modal>
    </div>
  );
}
