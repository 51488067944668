import { Button, TextField } from '@material-ui/core';
import SimpleModal from 'components/shared/SimpleModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { saveFund, setShowFundModal } from 'store/fundManagement.slice';

export function FundModal(props: any) {
  const dispatch = useDispatch();

  const { selectedFund, showFundModal } = useSelector((state: RootState) => state.fundManagement);
  const [ fundName, setFundName] = useState('');

  const save= () => {
    dispatch(saveFund({id: selectedFund.id, name: fundName}));
    dispatch(setShowFundModal(false));
  };

  useEffect(() => {
    setFundName(selectedFund.name);
}, [selectedFund]);

  const body = (
      <div>
        <h2 id="simple-modal-title">Save Fund</h2>
        <TextField
          style={{width: '100%', marginBottom: '16px'}}
          label="Fund Name"
          variant="outlined"
          value={fundName}
          onChange={(e: any) => {setFundName(e.target.value)}}
        />
        <div className="text-right">
            <Button className="mr-2" variant="contained" color="default" onClick={() => {dispatch(setShowFundModal(false))}}>
                Cancel
            </Button>
            <Button disabled={!fundName} variant="contained" color="primary" onClick={save}>
                Save
            </Button>
        </div>
      </div>
  );

	return (
        <SimpleModal open={showFundModal} body={body} />
      );
}