import { combineReducers } from 'redux';
import notification from '../store/notification.slice';
import auth from '../store/auth.slice';
import main from '../store/main.slice';
import snapshot from '../store/snapshot.slice';
import alerts from '../store/alerts.slice';
import fundManagement from 'store/fundManagement.slice';
import logs from 'store/logs.slice';
import strategies from 'store/strategies.slice';
import dashboard from 'store/dashboard.slice';

const rootReducer = combineReducers({
	notification,
	auth,
	dashboard,
	main,
	snapshot,
	alerts,
	strategies,
	fundManagement,
	logs
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;