import { Button, TextField } from '@material-ui/core';
import SimpleModal from 'components/shared/SimpleModal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setDecryptionKey, setShowDecryptionKey } from 'store/auth.slice';

export function DecryptionKeyModal(props: any) {
  const dispatch = useDispatch();

  const [ localDecryptionKey, setLocalDecryptionKey] = useState('');
  const { showDecryptionKey } = useSelector((state: RootState) => state.auth);

  const submitDecryptionKey = () => {
    dispatch(setDecryptionKey(localDecryptionKey));
    props.postSubmit();
  };

  const body = (
      <div>
        <h2 id="simple-modal-title">Decryption Key</h2>
        <TextField
          style={{width: '100%', marginBottom: '16px'}}
          label="Decryption Key (min 6 chars)"
          type="password"
          variant="outlined"
          onChange={(e: any) => {setLocalDecryptionKey(e.target.value)}}
        />
        <div className="text-right">
            <Button className="mr-2" variant="contained" color="default" onClick={() => {dispatch(setShowDecryptionKey(false))}}>
                Cancel
            </Button>
            <Button disabled={localDecryptionKey.length < 6} variant="contained" color="primary" onClick={submitDecryptionKey}>
                Submit
            </Button>
        </div>
      </div>
  );

	return (
        <SimpleModal open={showDecryptionKey} body={body} />
      );
}