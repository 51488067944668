import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setSelectedStrategy } from 'store/strategies.slice';
import { strategies, STRATEGY_ANCHOR, STRATEGY_SOLANA } from 'utils/constants';
import { Anchor } from './Anchor';
import { Solana } from './Solana';

export function Strategies(props: any) {
    const dispatch = useDispatch();

    const { selectedStrategy } = useSelector((state: RootState) => state.strategies);

    const changeStrategy = (strategy: string) => {
        dispatch(setSelectedStrategy(strategy));
    };

	return (
        <div className="App text-left">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid item style={{display:'inline-block'}}><h2 className="header-text">Select Strategy</h2></Grid>
                    <Grid item style={{display:'inline-block', marginLeft: '32px'}}>
                        <FormControl fullWidth style={{marginTop: '-10px', marginBottom: '20px', minWidth: '200px'}}>
                            <InputLabel id="demo-simple-select-label">Strategy</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Fund"
                                value={selectedStrategy}
                                onChange={(e) => {changeStrategy(e.target.value as string)}}
                                >
                                {
                                    strategies.map((strategy, index) => (
                                        <MenuItem key={index} value={strategy.Name}>{strategy.Name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            {
              selectedStrategy === STRATEGY_ANCHOR ? <Anchor /> : null  
            }    
            {
              selectedStrategy === STRATEGY_SOLANA ? <Solana /> : null  
            }
        </div>
      );
}