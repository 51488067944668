import './App.css';
import { Login } from 'components/login/Login';
import { Dashboard } from 'components/dashboard/Dashboard';
import { Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AddAlertOutlined, AttachMoney, Camera, DashboardOutlined, ExitToAppSharp, Money, Person, TrackChanges } from '@material-ui/icons';
import {useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setAxios, setDecryptionKey, setLogout, setToken, setUser } from 'store/auth.slice';
import { useToasts } from 'react-toast-notifications';
import { Profile } from 'components/profile/profile';
import { Snapshots } from 'components/snapshots/snapshots';
import { Logs } from 'components/logs/Logs';
import { Strategies } from 'components/strategies/Strategies';
import { FundManagement } from 'components/fund-management/FundManagements';
import { FILTER_ALERT } from 'utils/constants';
import { setSelectedFilterLog } from 'store/logs.slice';


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

export default function PersistentDrawerLeft() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const { addToast, removeAllToasts } = useToasts();
  const { error, info, success, warning } = useSelector((state: RootState) => state.notification);
  const { user, defaultPath } = useSelector((state: RootState) => state.auth);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getIcon = (value: string) => {
    if(value === "Alerts") {
      return <AddAlertOutlined />;
    }
    else if(value === "Strategies") {
      return <Money />;
    }
    else if(value === "Dashboard") {
      return <DashboardOutlined />;
    }
    else if(value === 'Fund Management') {
      return <AttachMoney />;
    }
    else if(value === 'Snapshots') {
      return <Camera />;
    }
    else if(value === 'Transaction Logs') {
      return <TrackChanges />;
    }
    else if(value === 'Logout') {
      return <ExitToAppSharp />;
    }
    else {
      return <Person />;
    }
  }

  const drawerClickHandler = (event: any, value: string) => {
    console.log(value);
    event.stopPropagation();

    if(value === "Strategies") {
      setAnchorEl(null);
      navigate('/strategies');
    }
    else if(value === "Alerts") {
      setAnchorEl(null);
      dispatch(setSelectedFilterLog(FILTER_ALERT));
      navigate('/logs');
    }
    else if(value === "Dashboard") {
      navigate('/dashboard');
    }
    else if(value === 'Fund Management') {
      navigate('/fund-management');
    }
    else if(value === 'Transaction Logs') {
      navigate('/logs');
    }
    else if(value === 'Logout') {
      dispatch(setLogout(navigate));
      navigate('/');
    }
    else if(value === user.username) {
      navigate('/profile');
    }
  }

  useEffect(() => {
    dispatch(setAxios(navigate));
    
    let user = localStorage.getItem('user');
    let token = localStorage.getItem('token');
    let decryptionKey = sessionStorage.getItem('decryptionKey');

    if(user && token) {
      user = JSON.parse(user);
      dispatch(setUser(user));
      dispatch(setToken(token));
      console.log(window.location.pathname);

      if(window.location.pathname === '/') {
        navigate(defaultPath);
      }
    }

    if(decryptionKey) {
      dispatch(setDecryptionKey(decryptionKey));
    }
    
  }, []);

  useEffect(() => {
    if(error) {
      addToast(error, { appearance: 'error' })
    }
    else {
      removeAllToasts();
    }
  }, [error])

  useEffect(() => {
    if(success) {
      addToast(success, { appearance: 'success' })
    }
    else {
      removeAllToasts();
    }
  }, [success])

  useEffect(() => {
    if(warning) {
      addToast(warning, { appearance: 'warning' })
    }
    else {
      removeAllToasts();
    }
  }, [warning])

  useEffect(() => {
    if(info) {
      addToast(info, { appearance: 'info' })
    }
    else {
      removeAllToasts();
    }
  }, [info])

  return (
    <div className={classes.root}>
        <CssBaseline />
        {
          user ? <>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Crypto Exploration
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => {handleDrawerClose()}}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {['Dashboard', 'Alerts', 'Strategies', 'Transaction Logs'].map((text, index) => (
              <ListItem button key={text}  onClick={(e) => {drawerClickHandler(e, text)}}>
                <ListItemIcon>{getIcon(text)}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {[user.username, 'Logout'].map((text, index) => (
              <ListItem button key={text}  onClick={(e) => {drawerClickHandler(e, text)}}>
                <ListItemIcon>{getIcon(text)}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        </>
        :
        null
        }
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/logs/:filter" element={user ? <Logs /> : <Login /> } />
              <Route path="/strategies" element={user ? <Strategies /> : <Login />} />
              <Route path="/dashboard" element={user ? <Dashboard /> : <Login />} />
              <Route path="/profile" element={user ? <Profile /> : <Login /> } />
              <Route path="/snapshots" element={user ? <Snapshots /> : <Login /> } />
              <Route path="/fund-management" element={user ? <FundManagement /> : <Login />} />
              <Route path="/logs" element={user ? <Logs /> : <Login /> } />
            </Routes>
        </main>
    </div>
  );
}